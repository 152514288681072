import React from 'react';
import EChartsReact from "echarts-for-react";
import { formatNumber, formatNumLabel, generateColorPalette } from '../../utils/graphUtils.tsx';
import '../../../style/graph.css'; // Importa il file CSS

const ScatterPlotComponent = ({ data, color = 'red' }) => {

    const option = {
        tooltip: {
            valueFormatter: formatNumber,
            textStyle: {
                fontFamily: 'Custom_font',
                align: 'left' // Allinea il testo del tooltip a sinistra
            },
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    color: '#ffffff' // Colore del testo del tooltip
                }
            }
        },
        grid: {
            left: '0%', // Spazio a sinistra per le etichette
            right: '5%',
            top: '0%',
            bottom: '0.1%',
            containLabel: true,
        },
        xAxis: [
            {  
                type: 'value',
                axisLabel: {  
                    formatter: formatNumLabel,
                    fontFamily: 'Custom_font',
                    alignWithLabel: true,
                    color: '#fff' // Colore delle etichette dell'asse X
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse x
                }
            }
        ],
        yAxis: [
            {  
                type: 'category',
                data: data['xAxis'],
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    fontFamily: 'Custom_font',
                    color: '#fff', // Colore delle etichette dell'asse Y
                    interval: 0, // Mostra tutte le etichette
                },
                //name: data['label'].padEnd(data['label'].length+(data['label'].length/2),' '),
                nameLocation: 'end', // Posizione del nome dell'asse Y
                nameTextStyle: { 
                    fontSize: 14, // Dimensione del testo del nome dell'asse Y
                    color: '#fff' // Colore del nome dell'asse Y
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse y
                }
            }
        ],
    series: [
        {
        symbolSize: 20,
        data: data['yAxis'],
        type: 'scatter'
        }
    ]
    };

    return (
        <div className="chart-container">
            <EChartsReact option={option} notMerge={true} style={{ height: `400px`, width: '95%' }} />
        </div>
    );
}

export default ScatterPlotComponent;