import React from "react";
import { Card, CardContent, Tooltip, IconButton, Grid, Typography, Box } from "@mui/material";
import '../../style/graph.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TextFilterComponent from "./TextFilterComponent.tsx";
import { Spa } from "@mui/icons-material";

interface MainIconCardDef {
    element: any, secondElement?: any, resize?: number, title?: string, tooltip?: string, filter?: boolean, filterValue?: string, handleFilterChange?: any
}

const MainCard: React.FC<MainIconCardDef> = ({ element, secondElement=undefined, resize=7, title='', tooltip= '', filter=false, filterValue = '', handleFilterChange = (value) => {}}) => {

    return (
        <Card style={{ borderRadius: '15px' }}>
            <CardContent>
                <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", paddingBottom:((title !== '')?'10px':'0px')}} >
                    <Grid 
                        item 
                        xs={8} 
                        container 
                        direction="row" 
                        alignItems="center" 
                        justifyContent="flex-start"
                        sx={{ gap: 1 }}
                        >
                        {title && (
                            <span className="graph-title" style={{ color: 'white' }}>
                            {title}
                            </span>
                        )}
                        {tooltip && (
                            <Tooltip 
                            title={<span style={{ fontSize: "1.2rem" }}>{tooltip}</span>} 
                            arrow
                            >
                            <IconButton sx={{ color: 'white' }}>
                                <HelpOutlineIcon />
                            </IconButton>
                            </Tooltip>
                        )}
                    </Grid>

                    {filter && <Grid 
                            item 
                            xs={4} 
                            container 
                            direction="row" 
                            justifyContent="flex-end" 
                            alignItems="center"
                            sx={{ 
                            // Aggiungi padding o margin se necessario
                            }}
                        >
                        <Box sx={{ maxWidth: '250px', width: '100%' }}>
                            <TextFilterComponent 
                                filterValue={filterValue} 
                                handleFilterChange={handleFilterChange}                                
                            />
                        </Box>
                    </Grid>}
                </Grid>

                <Grid container direction="row" sx={{ justifyContent: "center", alignItems: "center" }} >
                    <Grid item xs={(secondElement)?resize:12}>
                        {element}
                    </Grid>
                    {secondElement && <Grid item xs={12-resize}>
                        {secondElement}
                    </Grid>}
                </Grid>
            </CardContent>
        </Card>
    );
}

export default MainCard;