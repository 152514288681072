export const GraphData = {
    'overview': [
        {title:'SCORECARD AUDIENCE', payload:{start_date: '', end_date: '', end_point: 'SCORECARDS', chart_type: 'SCORECARDS'}, tooltip:"Questa sezione sintetizza i dati principali ed evidenzia l'andamento negli ultimi 90 giorni. Numero di utenti totali: Utenti loggati e non loggati da tutte le piattaforme digitali. Touchpoints totali: Numero complessivo di contatti lordi. Web impressions: Impression totali generate sul web. Percentuale utenti loggati: Utenti loggati in rapporto al totale delle sessioni di ascolto e visualizzazioni web."},
        {title:'SCORECARD SOCIAL', filtersList:['social_pages_page_name'], payload:{start_date: '', end_date: '', page_name:[], end_point: 'social_scorecards', chart_type: 'social_scorecards'}, tooltip:"Metriche aggregative specifiche delle piattaforme social Facebook e Instagram. Reach complessiva: Comprende le metriche di reach (Instagram) e page_impressions_unique (Facebook). Impressions complessive: Include le impression (Instagram) e page_impressions (Facebook). Numero di contenuti pubblicati: Totale di post, reel e video pubblicati su Facebook e Instagram."},
        
        {title:'AUDIENCE', payload:{start_date: '', end_date: '', end_point: 'AUDIENCE', chart_type: 'AUDIENCE'}, tooltip:"Visualizza la distribuzione dei touchpoints in base ai canali di fruizione, differenziando tra Radio e Radiovisione."},
        
        {title:'CANALI | PROGRAMMI LIVE', payload:{start_date: '', end_date: '', end_point: 'CONTENUTI_LIVE', chart_type: 'CONTENUTI_LIVE'}, tooltip:"Presenta la distribuzione dei touchpoints riferiti ai programmi live, con distinzione per canale e sorgente (Radio o Radiovisione)."},
        {title:'CANALE | SERIE | PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["channel","series","program"], channel:[], series:[], program: [], end_point: 'contenuti_on_demand', chart_type:"CONCAT_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints associati ai contenuti on demand."},
        
        {title:'SOCIAL REACH', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["reach","page_engagement"], end_point: 'social_pages', chart_type: "PIE_CHART"}, tooltip:"Indica la distribuzione percentuale della reach tra le pagine social attive su Facebook e Instagram."},
        {title:'SOCIAL IMPRESSIONS', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["impressions","page_impressions"], end_point: 'social_pages', chart_type: "PIE_CHART"}, tooltip:"Evidenzia la distribuzione percentuale delle impressions tra le diverse pagine social attive su Facebook e Instagram."},
        {title:'SOCIAL NUM. CONTENUTI PUBBLICATI', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["impressions","page_impressions"], end_point: 'social_pages', chart_type: "PIE_CHART"}, tooltip:""},

        {title:'PIATTAFORMA', payload: { start_date:"", end_date:"", group_by:["macro_category"], macro_category:[], platform:[], end_point: 'platforms', chart_type:"PIE_CHART"}, tooltip:"Fornisce la distribuzione dei touchpoints in base alla categoria di device utilizzati."},

        {title:'UTENTI UNICI', payload:{ start_date: '', end_date: '', end_point: 'TER1_1', chart_type: 'TER1_1' }, tooltip:"Utenti unici nel giorno medio per piattaforma (ultima estrazione dati TER)"},
        {title:'FREQUENZA', payload:{ start_date: '', end_date: '', end_point: 'TER1_2', chart_type: 'TER1_2' }, tooltip:"Frequenze nel giorno medio per piattaforma (ultima estrazione dati TER)"}, 
        {title:'CONTATTI EQUIVALENTI', payload:{ start_date: '', end_date: '', end_point: 'TER1_3', chart_type: 'TER1_3' }, tooltip:"Contatti equivalenti nel giorno medio per piattaforma (ultima estrazione dati TER)"},
        {title:'DETTAGLIO', payload:{ end_point: 'TER2', chart_type: 'TER2'}, tooltip:"Dettaglio ascolti dati TER per canale"}
    ], 
    'audience': [
        {title:'CANALI', payload:{start_date: '', end_date: '', group_by: ["name"], name:[], macro_category:[], type: "LIVE", chart_type: "PIE_CHART"}, tooltip:"Visualizza la distribuzione dei touchpoints per i canali di fruizione, differenziando tra Radio e Radiovisione."},
        {title:'TOUCHPOINTS VS. UTENTI UNICI', payload:{start_date: '', end_date: '', group_by: ["day_param"],  name:[], macro_category:[], chart_type: "MIXER_BAR_LINE_CHART"}, tooltip:"Visualizza la distribuzione giornaliera dei touchpoints e degli utenti unici. Notare che l’asse Y ha due scale differenti"},
        {title:'DISTRIBUZIONE TOUCHPOINTS SUL GIORNO', payload:{start_date: '', end_date: '', group_by: ["hour_trunc_param"],  name:[], macro_category:[], chart_type: "MIXER_BAR_LINE_CHART"}, tooltip:"Mostra l’aggregazione oraria dei touchpoints rispetto all’intervallo temporale selezionato. Da leggere come: “tutti i giorni alle ore 9…”."},
        {title:'PIATTAFORMA', payload:{start_date: '', end_date: '', group_by: ["macro_category"],  name:[], macro_category:[], chart_type: "PIE_CHART"}, tooltip:"Rappresenta la distribuzione in percentuale dei touchpoints rispetto alla piattaforma utilizzata per la fruizione dei contenuti."}
    ],
    'real_time': [
        {title:'ON AIR', filtersList:['contenuti_live_hours'], payload:{start_date:"", end_date:"",group_by:["hour_param","name"], live_content:true, chart_type:"STACKED_BAR_CHART"}, tooltip:"Rappresenta il numero di sessioni di ascolto effettuate dagli utenti. La granularità temporale è espressa in quarti d’ora per visualizzazioni con intervallo temporale tra 3 e 24 ore, un’ora per intervallo temporale maggiore ad 1 settimana."},
        {title:'COMPARAZIONE SESSIONI', filtersList:[], payload:{start_date:"", end_date:"", group_by: [], chart_type:"MIXER_BAR_LINE_CHART"}, tooltip:"Comparazione del numero di sessioni calcolate secondo la logica adottata da RTL rispetto al token di sessione di Mainstreaming."}
    ],
    'contenuti_live': [
        {title:'AUDIENCE CANALI LIVE', payload:{start_date:"", end_date:"",group_by:["name"], name:[], program_title:[], chart_type:"PIE_CHART"}, tooltip:"Rappresenta la distribuzione percentuale e assoluta dei touchpoints rispetto ai canali live (Radio e Radiovisione). "},
        {title:'PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["program_title"], name:[], program_title:[], chart_type:"SINGLE_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto ai diversi programmi."},
        {title:'CANALI | PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["name","program_title"], name:[], program_title:[], chart_type:"CONCAT_BAR_CHART"}, tooltip:"Visualizza la distribuzione dei touchpoints rispetto ai canali e ai programmi, differenziando per sorgente Radio e Radiovisione."},
        {title:'CONTENUTI PLAY', payload: {start_date:"", end_date:"",group_by:["day_param","name"], name:[], program_title:[], chart_type:"STACKED_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints su base giornaliera rispetto ai canali e alla sorgente (Radio e Radiovisione) "},
        {title:'SPEAKERS', payload: {start_date:"", end_date:"", group_by:["speaker_name"], name:[], program_title:[], chart_type:"SINGLE_BAR_CHART"}, tooltip:"Visualizza il numero di touchpoints per gruppo di speakers. "}
    ], 
    'contenuti_on_demand': [
        {title:'CANALE', payload: {start_date:"",end_date:"", group_by:["channel"], channel:[], series:[], program: [], chart_type:"PIE_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto ai canali on demand."},
        {title:'SERIE', payload: {start_date:"",end_date:"", group_by:["series"], channel:[], series:[], program: [], chart_type:"PIE_CHART"}, tooltip:"Rappresenta la distribuzione di touchpoints rispetto alle serie on demand."},
        {title:'CANALE | SERIE | PROGRAMMA', payload: {start_date:"", end_date:"", group_by:["channel","series","program"], channel:[], series:[], program: [], chart_type:"CONCAT_BAR_CHART"}, tooltip:"Rappresenta la distribuzione di touchpoints rispetto a canali, serie e programmi on demand."},
        {title:'[PROGRAMMA] | TITOLO', payload: {start_date:"", end_date:"", group_by:["program","name"], channel:[], series:[], program: [],  chart_type:"CONCAT_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto al singolo contenuto on demand. Evidenzia anche il canale (se presente)."},
        {title:'PROGRAMMA', payload: {start_date:"",end_date:"",group_by:["program"], channel:[], series:[], program: [], chart_type:"SINGLE_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto ai programmi on demand."},
        {title:'CONTENUTO', payload: {start_date:"",end_date:"",group_by:["content"], channel:[], series:[], program: [], chart_type:"SINGLE_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto ai contenuti on demand."},
    ],
    'music': [
        {title:'TOP CANZONI', payload: {start_date:"",end_date:"",group_by:["mus_sng_title"], source:[], title:[], chart_type:"SINGLE_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto alle singole canzoni trasmesse su tutti i canali."},
        {title:'TOP ARTISTI', payload: {start_date:"",end_date:"",group_by:["mus_art_name"], source:[], title:[],chart_type:"SINGLE_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto agli artisti trasmessi su tutti i canali."},
        {title:'TOP ARTISTI | PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["mus_art_name","title"], source:[], title:[], chart_type:"CONCAT_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto alle singole canzoni trasmesse durante i diversi programmi."},
        {title:'TOP CANZONI | PROGRAMMI', payload: {start_date:"", end_date:"", group_by:["mus_sng_title","title"], source:[], title:[], chart_type:"CONCAT_BAR_CHART"}, tooltip:"Rappresenta la distribuzione dei touchpoints rispetto agli artisti trasmessi durante i diversi programmi."}
    ],
    'advertising': [
        {title:'CANALI', payload: {start_date:"",end_date:"",group_by:["source"],source:[], name:[], chart_type:"PIE_CHART"}, tooltip:"Rappresenta la distribuzione percentuale e assoluta dei touchpoints dei contenuti commerciali per i diversi canali. L’aggregazione considera i singoli segmenti di fruizione di uno spot. "},
        {title:'PROGRAMMI', payload: {start_date:"",end_date:"",group_by:["title"],source:[], name:[],chart_type:"PIE_CHART"}, tooltip:"Rappresenta la distribuzione in percentuale e assoluta dei touchpoints di contenuti commerciali rispetto ai diversi programmi trasmessi. L’aggregazione considera i singoli segmenti di fruizione di uno spot. "},
        {title:'ADVERTISING', payload: {start_date:"",end_date:"",group_by:["name"],source:[], name:[],chart_type:"SINGLE_BAR_CHART"}, tooltip:"Rappresenta il numero di touchpoints per singolo spot pubblicitario. "},
        {title:'FRUIZIONE ADVERTISING', payload: {start_date:"",end_date:"",group_by:["day_param"], source:[], name:[],chart_type:"SINGLE_BAR_CHART"}, tooltip:"Rappresenta la distribuzione su base giornaliera di touchpoints per i contenuti pubblicitari. L’aggregazione considera i singoli segmenti di fruizione di uno spot."}
    ],
    'platforms': [
        {title:'CATEGORIA', payload: {start_date:"",end_date:"",group_by:["macro_category"],macro_category:[],platform:[],chart_type:"PIE_CHART"}, tooltip:"Rappresenta la distribuzione in percentuale e assoluta dei touchpoints relativi alle categorie di device utilizzati per la fruizione dei contenuti."},
        {title:'AUDIENCE PER PIATTAFORMA', payload: {start_date:"",end_date:"",group_by:["day_param","macro_category"],macro_category:[],platform:[],chart_type:"STACKED_BAR_CHART"}, tooltip:"Rappresenta la distribuzione su base giornaliera dei touchpoints per le diverse categorie di dispositivi."},
        {title:'SISTEMI OPERATIVI', payload: {start_date:"",end_date:"",group_by:["platform"],macro_category:[],platform:[],chart_type:"PIE_CHART"}, tooltip:"Rappresenta la distribuzione di touchpoints per sistemi operativi utilizzati dagli utenti durante la fruizione di contenuti."},
        {title:'VERSIONE SISTEMI OPERATIVI', payload: {start_date:"",end_date:"",group_by:["os_name"],macro_category:[],platform:[],chart_type:"SINGLE_BAR_CHART"}, tooltip:"Rappresenta la distribuzione di touchpoints per versione di sistema operativo utilizzato dagli utenti durante la fruizione di contenuti."}
    ],
    'social_pages': [
        {title:'SCORECARD SOCIAL', filtersList:['social_pages_page_name'], payload:{start_date: '', end_date: '', page_name:[], chart_type: 'SCORECARD'}, tooltip:"Metriche aggregative specifiche delle piattaforme social Facebook e Instagram. Reach complessiva: Somma delle metriche di reach (Instagram) e page_impressions_unique (Facebook).Impressions complessive: Somma delle impression (Instagram) e page_impressions (Facebook).Numero di contenuti pubblicati: Totale di post, reel e video pubblicati su Facebook e Instagram."},

        {title:'REACH', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["reach","page_engagement"], chart_type: "PIE_CHART"}, tooltip:"Indica la distribuzione percentuale della reach tra le pagine social attive su Facebook e Instagram."},
        {title:'IMPRESSIONS', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["impressions","page_impressions"], chart_type: "PIE_CHART"}, tooltip:"Evidenzia la distribuzione percentuale delle impressions tra le diverse pagine social attive su Facebook e Instagram."},
        {title:'NUM. CONTENUTI PUBBLICATI', filtersList:['social_pages_page_name'], payload: {start_date:"",end_date:"", group_by: ["page_name"], page_name:[], metric_name:["impressions","page_impressions"], chart_type: "PIE_CHART"}, tooltip:" "},

        {title:'FACEBOOK', filtersList:['social_pages_page_name','social_pages_facebook_metric_name'], payload:{start_date: '', end_date: '', group_by: ["day_param"], page_name:[], metric_name:["page_post_engagements"], chart_type: "MIXER_BAR_LINE_CHART"}, tooltip:"Rappresenta l’andamento su base giornaliera della metrica (tra quelle disponibili per Facebook) e sul periodo selezionati."},
        {title:'INSTAGRAM', filtersList:['social_pages_page_name','social_pages_instagram_metric_name'], payload:{start_date: '', end_date: '', group_by: ["day_param"], page_name:[], metric_name:["reach"], chart_type: "MIXER_BAR_LINE_CHART"}, tooltip:"Rappresenta l’andamento su base giornaliera della metrica (tra quelle disponibili per Instagram) e sul periodo selezionati. "},
        {title:'TIKTOK', filtersList:['social_pages_page_name','social_pages_tiktok_metric_name'], payload:{start_date: '', end_date: '', group_by: ["day_param"], page_name:[], metric_name:[], chart_type: "MIXER_BAR_LINE_CHART"}, tooltip:""},
        
        {title:'POST FACEBOOK PER UTENTI RAGGIUNTI', columnTableList: ['post_title',"post_impressions", "post_impressions_unique", "post_reactions_like_total" ], filtersList:['social_pages_page_name'], payload: {start_date:'', end_date:'', group_by:["post_title","metric_name"], page_name:[], source_social:["Facebook"], metric_name:["post_impressions", "post_impressions_unique", "post_reactions_like_total"], chart_type:"SOCIAL_TABLE_CHART"}, tooltip:"Rappresenta il valore massimo raggiunto dalle diverse metriche per i post pubblicati su Facebook."},
        {title:'POST E REEL INSTAGRAM PER UTENTI RAGGIUNTI', columnTableList: ['post_title',"impressions", "reach", "likes"], filtersList:['social_pages_page_name'], payload: {start_date:'', end_date:'', group_by:["post_title","metric_name"], page_name:[], source_social:["Instagram"], metric_name:["impressions", "reach", "likes"], chart_type:"SOCIAL_TABLE_CHART"}, tooltip:"Rappresenta il valore massimo raggiunto dalle diverse metriche per i contenuti pubblicati su Instagram."}
    ],
    'uae': [
        {title:'DAY', payload: {start_date:"",end_date:"", granularity:'DAY', group_by:[], chart_type: "MIXED_LINE_BARS_CHART"}, tooltip:"Rappresenta il valore in GVaux della distribuzione giornaliera dell’Unità di Ascolto Equivalente (UAE) e la distribuzione percentuale dei contatti lordi tra le sorgenti Radio, TV e Digital.Il calcolo avviene considerando i dati editoriali e di mercato impostati nei settings di piattaforma.L’UAE considera per la Radio e la TV il valore dei contatti sul giorno medio fornito dai dati TER, e per “Digital” il numero di contatti lordi (touchpoints) su base giornaliera presenti in piattaforma."},
        {title:'MONTH', payload: {start_date:"",end_date:"", granularity:'MONTH', group_by:[], chart_type: "MIXED_LINE_BARS_CHART"}, tooltip:"Rappresenta il valore in GVaux della distribuzione mensile dell’Unità di Ascolto Equivalente (UAE) e la distribuzione percentuale dei contatti lordi tra le sorgenti Radio, TV e Digital."}
    ]
};

export const DefaultValues = {
    social_pages_facebook_metric_name: "page_post_engagements",
    social_pages_instagram_metric_name: "reach",
    contenuti_live_hours: "Ultime 6 ore (15 min.)"
}

export const FilterData = {
    'overview': {},
    'audience': {table_name: 'audience', columns: ['name', 'macro_category'], columns_alias:['Canale', 'Piattaforma'],  type: 'LIVE'},
    'real_time': {table_name: 'real_time', columns: [], localFilters:["contenuti_live_hours"], columns_alias: ['Intervallo temporale']},
    'contenuti_live': {table_name: 'contenuti_live', columns: ['name', 'program_title'], columns_alias: ['Canali', 'Programma']},
    'contenuti_on_demand': {table_name: 'contenuti_on_demand', columns: ['channel', 'series','program'], columns_alias: ['Canale', 'Serie','Programma']},
    'music': {table_name:'music', columns: ['source','title'], columns_alias: ['Sorgente','Titolo']},
    'advertising': {table_name:'advertising', columns: ['source','name'], columns_alias: ['Canale','ADV']},
    'platforms': {table_name:'platforms', columns: ['macro_category','platform'], columns_alias: ['Piattaforma','Sistema operativo']},
    'social_pages': {table_name:'social_pages', columns:["page_name", "metric_name"],
        localFilters:["social_pages_page_name", "social_pages_facebook_metric_name", "social_pages_instagram_metric_name", "social_pages_tiktok_metric_name"],
        columns_alias: ['Pagina']},
    'uae': {table_name: 'uae', columns: [], columns_alias: []}
}