import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';

export default function SwitchJobStatusDialogComponent ({ dialogData, handleClose }) {
  const [text, setText] = React.useState<string>();

  React.useEffect(() => {
    if(dialogData.open){
      switch(dialogData.action){
        case 'start': setText('Vuoi avviare il job?'); break;
        case 'stop': setText('Vuoi stoppare il job?'); break;
        case 'restart': setText('Vuoi riavviare il job?'); break;
        default: break;
      }
    }
  }, [dialogData])

  return (<>
      {text &&
        <Dialog
          open={dialogData.open}
          onClose={() => handleClose(false, '', '')}
          sx={{minWidth: '360.700px'}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Attenzione"}</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description"> {text} </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => handleClose(false, '', '')} color="primary" autoFocus>
              No
            </Button>
            <Button onClick={() => handleClose(true, dialogData.name, dialogData.action )} color="primary">
              Si
            </Button>
          </DialogActions>
        </Dialog>
      }
      </>
    );
  };