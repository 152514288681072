import React from "react";
import { Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import PieChartComponent from '../graphs/PieChart/PieChartComponent.tsx';
import MixedChartComponent from "../graphs/MixedChart/MixedChartComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";
import { filterGraphValue, processData, transformDataToBarChart } from "../utils/graphUtils.tsx";

export const Audience = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)

    const [filteredDataGraph1, setFilteredDataGraph1] = React.useState<any>(null)
    const [filterGraph1, setFilterGraph1] = React.useState('')

    const {getToken} = useAuth();

    const setDataGraph = ( index, responseData ) => {        
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 2: setDataGraph3(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 3: setDataGraph4(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    React.useEffect(() => {
        if(dataGraph1){ 
            let newList = filterGraphValue(transformDataToBarChart(dataGraph1.data), filterGraph1)
            setFilteredDataGraph1(newList)
        }
    }, [dataGraph1, filterGraph1]);

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0,1,2,3], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph1 && dataGraph1.data && filteredDataGraph1)
                    ?<MainCard element={<PieChartComponent data={dataGraph1.data} isLargeScreen={isLargeScreen} type={'perc'}/>}
                        secondElement={<BarChartWithLabelsComponent data={filteredDataGraph1} splitted={true} sizeGraph={"small"}/>} filter={true} filterValue={filterGraph1} handleFilterChange={setFilterGraph1} title={dataGraph1.title} tooltip={currentDashboardGraph[0].tooltip}/>
                    :<SkeletonComponent/>}                
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph2 && dataGraph2.data) 
                    ?<MainCard element={<MixedChartComponent data={dataGraph2.data} color={'red'} type="line" />} title={dataGraph2.title} tooltip={currentDashboardGraph[1].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph3 && dataGraph3.data)
                    ?<MainCard element={<MixedChartComponent data={dataGraph3.data} />} title={dataGraph3.title} tooltip={currentDashboardGraph[2].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph4 && dataGraph4.data)
                    ?<MainCard element={<PieChartComponent data={processData(dataGraph4.data, false)} isLargeScreen={isLargeScreen}/>} title={dataGraph4.title + ' CATEGORIZZATA'} tooltip={currentDashboardGraph[3].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph4 && dataGraph4.data)
                    ?<MainCard element={<PieChartComponent data={processData(dataGraph4.data, true)} isLargeScreen={isLargeScreen}/>} title={dataGraph4.title} tooltip={currentDashboardGraph[3].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>
        </Grid>        
    )
}