import React from "react";
import { Grid, Typography, ThemeProvider, Card, CardContent } from "@mui/material";
import { darkTheme } from "../utils/graphUtils.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";

// Help Sections Data
const helpSectionsOverview = [
  {
    title: "HIGHLIGHTS",
    description: "Questa sezione sintetizza i dati principali ed evidenzia l'andamento negli ultimi 90 giorni. Numero di utenti totali: Utenti loggati e non loggati da tutte le piattaforme digitali. Touchpoints totali: Numero complessivo di contatti lordi. Web impressions: Impression totali generate sul web. Percentuale utenti loggati: Utenti loggati in rapporto al totale delle sessioni di ascolto e visualizzazioni web."
  },
  {
    title: "HIGHLIGHTS SOCIAL",
    description: "Metriche aggregative specifiche delle piattaforme social Facebook e Instagram. Reach complessiva: Comprende le metriche di reach (Instagram) e page_impressions_unique (Facebook). Impressions complessive: Include le impression (Instagram) e page_impressions (Facebook). Numero di contenuti pubblicati: Totale di post, reel e video pubblicati su Facebook e Instagram."
  },
  {
    title: "AUDIENCE",
    description: "Visualizza la distribuzione dei touchpoints in base ai canali di fruizione, differenziando tra Radio e Radiovisione."
  },
  {
    title: "CANALI E PROGRAMMI LIVE",
    description: "Presenta la distribuzione dei touchpoints riferiti ai programmi live, con distinzione per canale e sorgente (Radio o Radiovisione)."
  },
  {
    title: "CANALE, SERIE E PROGRAMMA ON DEMAND",
    description: "Rappresenta la distribuzione dei touchpoints associati ai contenuti on demand."
  },
  {
    title: "SOCIAL REACH",
    description: "Indica la distribuzione percentuale della reach tra le pagine social attive su Facebook e Instagram."
  },
  {
    title: "SOCIAL IMPRESSIONS",
    description: "Evidenzia la distribuzione percentuale delle impressions tra le diverse pagine social attive su Facebook e Instagram."
  },
  {
    title: "PIATTAFORMA",
    description: "Fornisce la distribuzione dei touchpoints in base alla categoria di device utilizzati."
  }
];

const helpSectionsBody = [
  {
    title: "AUDIENCE - CANALI",
    description: "Visualizza la distribuzione dei touchpoints per i canali di fruizione, differenziando tra Radio e Radiovisione."
  },
  {
    title: "TOUCHPOINTS VS. UTENTI UNICI",
    description: "Visualizza la distribuzione giornaliera dei touchpoints e degli utenti unici. Notare che l’asse Y ha due scale differenti."
  },
  {
    title: "DISTRIBUZIONE SESSIONI SUL GIORNO",
    description: "Mostra l’aggregazione oraria dei touchpoints rispetto all’intervallo temporale selezionato. Da leggere come: “tutti i giorni alle ore 9…”."
  },
  {
    title: "PIATTAFORMA CATEGORIZZATA",
    description: "Rappresenta la distribuzione in percentuale dei touchpoints rispetto alla piattaforma utilizzata per la fruizione dei contenuti."
  },
  {
    title: "PIATTAFORMA",
    description: "Compara il numero di touchpoints per device categorizzati rispetto a quelli non categorizzati."
  },
  {
    title: "REAL TIME - ON AIR",
    description: "Rappresenta il numero di sessioni di ascolto effettuate dagli utenti. La granularità temporale è espressa in quarti d’ora per visualizzazioni con intervallo tra 3 e 24 ore, e in ore per intervalli maggiori ad 1 settimana."
  },
  {
    title: "CONTENUTI LIVE - AUDIENCE CANALI LIVE",
    description: "Rappresenta la distribuzione percentuale e assoluta dei touchpoints rispetto ai canali live (Radio e Radiovisione)."
  },
  {
    title: "PROGRAMMI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto ai diversi programmi."
  },
  {
    title: "CANALI E PROGRAMMI",
    description: "Visualizza la distribuzione dei touchpoints rispetto ai canali e ai programmi, differenziando per sorgente Radio e Radiovisione."
  },
  {
    title: "CONTENUTI PLAY",
    description: "Rappresenta la distribuzione dei touchpoints su base giornaliera rispetto ai canali e alla sorgente (Radio e Radiovisione)."
  },
  {
    title: "SPEAKERS",
    description: "Visualizza il numero di touchpoints per gruppo di speakers."
  },
  {
    title: "CONTENUTI ON DEMAND - CANALE",
    description: "Rappresenta la distribuzione dei touchpoints rispetto ai canali on demand."
  },
  {
    title: "CONTENUTI ON DEMAND - SERIE",
    description: "Rappresenta la distribuzione di touchpoints rispetto alle serie on demand."
  },
  {
    title: "CONTENUTI ON DEMAND - CANALE | SERIE | PROGRAMMA",
    description: "Rappresenta la distribuzione di touchpoints rispetto a canali, serie e programmi on demand."
  },
  {
    title: "CONTENUTI ON DEMAND - [PROGRAMMA] | TITOLO",
    description: "Rappresenta la distribuzione dei touchpoints rispetto al singolo contenuto on demand, evidenziando anche il canale (se presente)."
  },
  {
    title: "CONTENUTI ON DEMAND - PROGRAMMA",
    description: "Rappresenta la distribuzione dei touchpoints rispetto ai programmi on demand."
  },
  {
    title: "CONTENUTI ON DEMAND - CONTENUTO",
    description: "Rappresenta la distribuzione dei touchpoints rispetto ai contenuti on demand."
  },
  {
    title: "MUSICA - TOP CANZONI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto alle singole canzoni trasmesse su tutti i canali."
  },
  {
    title: "MUSICA - TOP ARTISTI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto agli artisti trasmessi su tutti i canali."
  },
  {
    title: "MUSICA - TOP CANZONI | PROGRAMMI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto alle singole canzoni trasmesse durante i diversi programmi."
  },
  {
    title: "MUSICA - TOP ARTISTI | PROGRAMMI",
    description: "Rappresenta la distribuzione dei touchpoints rispetto agli artisti trasmessi durante i diversi programmi."
  },
  {
    title: "AUDIENCE - CANALI",
    description: "Rappresenta la distribuzione percentuale e assoluta dei touchpoints dei contenuti commerciali per i diversi canali. L’aggregazione considera i singoli segmenti di fruizione di uno spot."
  },
  {
    title: "PROGRAMMI",
    description: "Rappresenta la distribuzione in percentuale e assoluta dei touchpoints di contenuti commerciali rispetto ai diversi programmi trasmessi. L’aggregazione considera i singoli segmenti di fruizione di uno spot."
  },
  {
    title: "ADVERTISING",
    description: "Rappresenta il numero di touchpoints per singolo spot pubblicitario."
  },
  {
    title: "FRUIZIONE ADVERTISING",
    description: "Rappresenta la distribuzione su base giornaliera di touchpoints per i contenuti pubblicitari. L’aggregazione considera i singoli segmenti di fruizione di uno spot."
  },
  {
    title: "PIATTAFORME - CATEGORIA",
    description: "Rappresenta la distribuzione in percentuale e assoluta dei touchpoints relativi alle categorie di device utilizzati per la fruizione dei contenuti."
  },
  {
    title: "AUDIENCE PER PIATTAFORMA",
    description: "Rappresenta la distribuzione su base giornaliera dei touchpoints per le diverse categorie di dispositivi."
  },
  {
    title: "SISTEMI OPERATIVI",
    description: "Rappresenta la distribuzione di touchpoints per sistemi operativi utilizzati dagli utenti durante la fruizione di contenuti."
  },
  {
    title: "VERSIONE SISTEMI OPERATIVI",
    description: "Rappresenta la distribuzione di touchpoints per versione di sistema operativo utilizzato dagli utenti durante la fruizione di contenuti."
  },
  {
    title: "SOCIAL - HIGHLIGHTS SOCIAL",
    description: "Metriche aggregative specifiche delle piattaforme social Facebook e Instagram.\nReach complessiva: Somma delle metriche di reach (Instagram) e page_impressions_unique (Facebook).\nImpressions complessive: Somma delle impression (Instagram) e page_impressions (Facebook).\nNumero di contenuti pubblicati: Totale di post, reel e video pubblicati su Facebook e Instagram."
  },
  {
    title: "SOCIAL REACH",
    description: "Indica la distribuzione percentuale della reach tra le pagine social attive su Facebook e Instagram."
  },
  {
    title: "SOCIAL IMPRESSIONS",
    description: "Evidenzia la distribuzione percentuale delle impressions tra le diverse pagine social attive su Facebook e Instagram."
  },
  {
    title: "FACEBOOK",
    description: "Rappresenta l’andamento su base giornaliera della metrica (tra quelle disponibili per Facebook) e sul periodo selezionato."
  },
  {
    title: "INSTAGRAM",
    description: "Rappresenta l’andamento su base giornaliera della metrica (tra quelle disponibili per Instagram) e sul periodo selezionato."
  },
  {
    title: "POST FACEBOOK PER UTENTI RAGGIUNTI",
    description: "Rappresenta il valore massimo raggiunto dalle diverse metriche per i post pubblicati su Facebook."
  },
  {
    title: "POST E REEL INSTAGRAM PER UTENTI RAGGIUNTI",
    description: "Rappresenta il valore massimo raggiunto dalle diverse metriche per i contenuti pubblicati su Instagram."
  },
  {
    title: "UAE - DAY",
    description: "Rappresenta il valore in GVaux della distribuzione giornaliera dell’Unità di Ascolto Equivalente (UAE) e la distribuzione percentuale dei contatti lordi tra le sorgenti Radio, TV e Digital.\nIl calcolo avviene considerando i dati editoriali e di mercato impostati nei settings di piattaforma.\nL’UAE considera per la Radio e la TV il valore dei contatti sul giorno medio fornito dai dati TER, e per “Digital” il numero di contatti lordi (touchpoints) su base giornaliera presenti in piattaforma."
  },
  {
    title: "UAE - MONTH",
    description: "Rappresenta il valore in GVaux della distribuzione mensile dell’Unità di Ascolto Equivalente (UAE) e la distribuzione percentuale dei contatti lordi tra le sorgenti Radio, TV e Digital."
  }
];

export const Help = () => {

  return (
    <ThemeProvider theme={darkTheme}>
        <Grid container spacing={3} sx={{ textAlign: 'justify' }}>
            {/* Titolo principale: OVERVIEW */}
            <Grid item xs={12}>
            <Typography variant="h4" gutterBottom style={{ color: "white" }}>
                OVERVIEW
            </Typography>
            <Typography variant="body1" style={{ color: "white", textAlign: "left", marginBottom: "10px" }}>
                La sezione Overview offre una panoramica generale dei dati principali raccolti dalle piattaforme digitali.
            </Typography>
            <ul style={{ color: "white", textAlign: "left", paddingLeft: "20px" }}>
                <li>Numero di utenti totali: Include utenti loggati e non loggati da tutte le piattaforme digitali.</li>
                <li>Numero di touchpoints: Contatti lordi registrati.</li>
                <li>Web impressions: Numero totale di impression sul web.</li>
                <li>Percentuale di utenti loggati: percentuale di utenti loggati rispetto al totale delle sessioni di ascolto e visualizzazioni web.</li>
                <li>Periodo di riferimento: Gli ultimi 90 giorni.</li>
            </ul>
        </Grid>


        {/* Altre sezioni */}
        {
          [...helpSectionsOverview.map((section, index) => (
            <Grid item xs={12} key={index}>
              <Card
                elevation={2}
                sx={{
                  backgroundColor: "#1e1e1e",
                  color: "white",
                  textAlign: "left"
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {section.title}
                  </Typography>
                  <Typography variant="body1">{section.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          )),
          ...helpSectionsOverview.map((section, index) => (
            <Grid item xs={12} key={index}>
              <Card
                elevation={2}
                sx={{
                  backgroundColor: "#1e1e1e",
                  color: "white",
                  textAlign: "left"
                }}
              >
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {section.title}
                  </Typography>
                  <Typography variant="body1">{section.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
          ]        
        }
      </Grid>
    </ThemeProvider>
  );
};

export default Help;