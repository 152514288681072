import React from "react";
import { Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import PieChartComponent from '../graphs/PieChart/PieChartComponent.tsx';
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";
import { filterGraphValue } from "../utils/graphUtils.tsx";

export const ContenutiOnDemand = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const [dataGraph5, setDataGraph5] = React.useState<any>(null)
    const [dataGraph6, setDataGraph6] = React.useState<any>(null)
    const {getToken} = useAuth();

    const [filteredDataGraph3, setFilteredDataGraph3] = React.useState<any>(null)
    const [filterGraph3, setFilterGraph3] = React.useState('')
    const [filteredDataGraph4, setFilteredDataGraph4] = React.useState<any>(null)
    const [filterGraph4, setFilterGraph4] = React.useState('')
    const [filteredDataGraph5, setFilteredDataGraph5] = React.useState<any>(null)
    const [filterGraph5, setFilterGraph5] = React.useState('')
    const [filteredDataGraph6, setFilteredDataGraph6] = React.useState<any>(null)
    const [filterGraph6, setFilterGraph6] = React.useState('')

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 2: setDataGraph3(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 3: setDataGraph4(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 4: setDataGraph5(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 5: setDataGraph6(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    React.useEffect(() => {
        if(dataGraph3){ 
            let newList = filterGraphValue(dataGraph3.data, filterGraph3)
            setFilteredDataGraph3(newList)
        }
    }, [dataGraph3, filterGraph3]);

    React.useEffect(() => {
        if(dataGraph4){ 
            let newList = filterGraphValue(dataGraph4.data, filterGraph4)
            setFilteredDataGraph4(newList)
        }
    }, [dataGraph4, filterGraph4]);

    React.useEffect(() => {
        if(dataGraph5){ 
            let newList = filterGraphValue(dataGraph5.data, filterGraph5)
            setFilteredDataGraph5(newList)
        }
    }, [dataGraph5, filterGraph5]);

    React.useEffect(() => {
        if(dataGraph6){ 
            let newList = filterGraphValue(dataGraph6.data, filterGraph6)
            setFilteredDataGraph6(newList)
        }
    }, [dataGraph6, filterGraph6]);

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0,1,2,3,4,5], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph1 && dataGraph1.data)
                    ?<MainCard element={<PieChartComponent data={dataGraph1.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph1.title} tooltip={currentDashboardGraph[0].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph2 && dataGraph2.data)
                    ?<MainCard element={<PieChartComponent data={dataGraph2.data} color={'blue'} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph2.title} tooltip={currentDashboardGraph[1].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(filteredDataGraph3)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph3}/>} filter={true} filterValue={filterGraph3} handleFilterChange={setFilterGraph3} title={dataGraph3.title} tooltip={currentDashboardGraph[2].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(filteredDataGraph4)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph4}/>} filter={true} filterValue={filterGraph4} handleFilterChange={setFilterGraph4} title={dataGraph4.title} tooltip={currentDashboardGraph[3].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >                
                <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph5)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph5} color={'blue'} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph5} handleFilterChange={setFilterGraph5} title={dataGraph5.title} tooltip={currentDashboardGraph[4].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph6)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph6} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph6} handleFilterChange={setFilterGraph6} title={dataGraph6.title} tooltip={currentDashboardGraph[5].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>            
        </Grid>
    )
}