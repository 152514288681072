import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainPageWrapper from "./components/routes/MainPage.tsx";
import Login from "./components/routes/Login.tsx";
import DefaultRoute from "./components/DefaultRoute.tsx";
import ProtectedRoute from "./components/Protected.tsx"
import UnprotectedRoute from "./components/Unprotected.tsx"
import {Auth0Provider} from "./components/context/Auth0Context.tsx";
// { useMediaQuery, useTheme } from '@mui/material';

function App() {    
    //const theme = useTheme();
    //const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Auth0Provider>
            <div className="App">
                <Router>
                    <Routes>
                        <Route 
                            path="/login"
                            element={<UnprotectedRoute element={<Login />}/>} 
                        />
                        <Route
                            path="/dashboards"
                            element={<ProtectedRoute element={<MainPageWrapper />}/>}
                        />                            
                        <Route
                            path="*"
                            element={<DefaultRoute/>}
                        />
                    </Routes>
                </Router>
            </div>
        </Auth0Provider>
    );
}

export default App;
