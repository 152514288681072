import React from "react";
import { colors, Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import ScoreCardComponent from "../elements/ScoreCardComponent.tsx";
import PieChartComponent from '../graphs/PieChart/PieChartComponent.tsx';
import HeatmapChartComponent from "../graphs/HeatMap/HeatMapTableComponent.tsx";
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import "../../style/dashboard.css";
import ScatterPlotComponent from "../graphs/ScatterPlot/ScatterPlotComponent.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";
import { filterGraphValue, transformDataToBarChart } from "../utils/graphUtils.tsx";

export const Overview = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const [dataGraph5, setDataGraph5] = React.useState<any>(null)
    const [dataGraph6, setDataGraph6] = React.useState<any>(null)
    const [dataGraph7, setDataGraph7] = React.useState<any>(null)
    const [dataGraph8, setDataGraph8] = React.useState<any>(null)
    const [dataGraph9, setDataGraph9] = React.useState<any>(null)
    const [dataGraph10, setDataGraph10] = React.useState<any>(null)
    const [dataGraph11, setDataGraph11] = React.useState<any>(null)
    const [dataGraph12, setDataGraph12] = React.useState<any>(null)
    const [dataGraph13, setDataGraph13] = React.useState<any>(null)

    const [filteredDataGraph3, setFilteredDataGraph3] = React.useState<any>(null)
    const [filterGraph3, setFilterGraph3] = React.useState('')
    const [filteredDataGraph4, setFilteredDataGraph4] = React.useState<any>(null)
    const [filterGraph4, setFilterGraph4] = React.useState('')
    const [filteredDataGraph5, setFilteredDataGraph5] = React.useState<any>(null)
    const [filterGraph5, setFilterGraph5] = React.useState('')

    const {getToken} = useAuth();
    const socialList = ['facebook','instagram'];

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 2: setDataGraph3(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 3: setDataGraph4(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 4: setDataGraph5(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 5: setDataGraph6(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 6: setDataGraph7(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 7: setDataGraph8(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 8: setDataGraph9(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 9: setDataGraph10(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 10: setDataGraph11(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 11: setDataGraph12(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 12: setDataGraph13(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0,1,2,3,4,5,6,7,8,9,10,11,12], setDataGraph);
    }, [currentDashboardGraph]);

    React.useEffect(() => {
        if(dataGraph3){ 
            let newList = filterGraphValue(transformDataToBarChart(dataGraph3.data), filterGraph3)
            setFilteredDataGraph3(newList)
        }
    }, [dataGraph3, filterGraph3]);

    React.useEffect(() => {
        if(dataGraph4){ 
            let newList = filterGraphValue(dataGraph4.data, filterGraph4)
            setFilteredDataGraph4(newList)
        }
    }, [dataGraph4, filterGraph4]);

    React.useEffect(() => {
        if(dataGraph5){ 
            let newList = filterGraphValue(dataGraph5.data, filterGraph5)
            setFilteredDataGraph5(newList)
        }
    }, [dataGraph5, filterGraph5]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} >{(dataGraph1 && dataGraph1.data)
                    ?<MainCard element={<ScoreCardComponent data={dataGraph1.data} direction="horizontal"/>} title={'HIGHLIGHTS'} tooltip={currentDashboardGraph[0].tooltip}/>
                    :<SkeletonComponent height='200px'/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} >{(dataGraph2 && dataGraph2.data)
                    ?<MainCard element={<ScoreCardComponent data={dataGraph2.data} direction="horizontal"/>} title={'HIGHLIGHTS SOCIAL'} tooltip={currentDashboardGraph[1].tooltip}/>
                    :<SkeletonComponent height='200px'/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph3 && dataGraph3.data && filteredDataGraph3)
                    ?<MainCard element={<PieChartComponent data={dataGraph3.data} isLargeScreen={isLargeScreen}/>}
                        secondElement={<BarChartWithLabelsComponent data={filteredDataGraph3} splitted={true} sizeGraph={"small"}/>} filter={true} filterValue={filterGraph3} handleFilterChange={setFilterGraph3} title={dataGraph3.title} tooltip={currentDashboardGraph[2].tooltip}/>
                    :<SkeletonComponent/>}                
                </Grid>
            </Grid>

            <Grid item xs={12}><div className="overview-tab-section">{'OVERVIEW CONTENUTI - 90 GIORNI'}</div></Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph4)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph4} color={'blue'} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph4} handleFilterChange={setFilterGraph4} title={dataGraph4.title} tooltip={currentDashboardGraph[3].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(filteredDataGraph5)
                    ?<MainCard element={<BarChartWithLabelsComponent data={filteredDataGraph5} sizeGraph={'small'}/>} filter={true} filterValue={filterGraph5} handleFilterChange={setFilterGraph5} title={dataGraph5.title} tooltip={currentDashboardGraph[4].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph6 && dataGraph6.data)?<MainCard element={<PieChartComponent data={dataGraph6.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph6.title} tooltip={currentDashboardGraph[5].tooltip}/> :<SkeletonComponent/>} </Grid>
                    <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph7 && dataGraph7.data)?<MainCard element={<PieChartComponent data={dataGraph7.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph7.title} tooltip={currentDashboardGraph[6].tooltip}/> :<SkeletonComponent/>} </Grid>
                    {/* <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph8 && dataGraph8.data)?<MainCard element={<PieChartComponent data={dataGraph8.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph8.title} tooltip={currentDashboardGraph[7].tooltip}/> :<SkeletonComponent/>} </Grid> */}
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph9 && dataGraph9.data)
                    ?<MainCard element={<PieChartComponent data={dataGraph9.data} isLargeScreen={isLargeScreen}/>} title={dataGraph9.title} tooltip={currentDashboardGraph[8].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph9 && dataGraph9.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={transformDataToBarChart(dataGraph9.data)} splitted={true} sizeGraph={"small"}/>} title={dataGraph9.title} tooltip={currentDashboardGraph[8].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}><div className="ter-tab-section"><span style={{color:'black'}}>{'>'}</span><span>{'DATI TER'}</span></div></Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph10 && dataGraph10.data) ? <MainCard element={<BarChartWithLabelsComponent data={dataGraph10.data} color={'neutral'} sizeGraph={'small'} sort={false}/>} title={dataGraph10.title} tooltip={currentDashboardGraph[9].tooltip}/>:<SkeletonComponent/>}</Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph11 && dataGraph11.data) ? <MainCard element={<ScatterPlotComponent data={dataGraph11.data}/>} title={dataGraph11.title} tooltip={currentDashboardGraph[10].tooltip}/>:<SkeletonComponent/>}</Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph12 && dataGraph12.data) ? <MainCard element={<BarChartWithLabelsComponent data={dataGraph12.data} color={'neutral'} sizeGraph={'small'} sort={false}/>} title={dataGraph12.title} tooltip={currentDashboardGraph[11].tooltip}/>:<SkeletonComponent/>}</Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph13 && dataGraph13.data)
                    ?<MainCard element={<HeatmapChartComponent data={dataGraph13.data} isLargeScreen={isLargeScreen} />} title={dataGraph13.title} tooltip={currentDashboardGraph[12].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>
        </Grid>
    )
}