import React from 'react';
import axios from 'axios';
import { Grid, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AutocompleteFilter from '../elements/AutocompleteFilter.tsx';
import { FilterContextEl, useFilter } from '../providers/FilterProvider.tsx';
import '../../style/home.css'
import dayjs from 'dayjs';
import { GraphData, FilterData, DefaultValues } from '../elements/DashboardElement.tsx';
import Dashboard from '../elements/Dashboard.tsx';
import { filter_style, onairLocalFilter } from '../utils/filterUtils.tsx';
import { darkTheme } from '../utils/graphUtils.tsx';

const DashboardContainer = ({indexDashboard, idDashboard, setIsError, isLargeScreen}) => {
    const { addFilters, isAllSelected, getFilterValueList, setFilterValueList, getFilteredOptionList } = useFilter();
    const [filtersList, setFiltersList] = React.useState<FilterContextEl[]>([]);
    const [localFiltersList, setLocalFiltersList] = React.useState<any[]>([]);
    const [currentDashboardGraph, setCurrentDashboardGraph] = React.useState<FilterContextEl[]>([]);

    function callMutationData(type='update') {
      const currentDashboard = GraphData[idDashboard.toLowerCase()]
      if(!['settings','help'].includes(idDashboard.toLowerCase()))
        if(currentDashboard) {
          setCurrentDashboardGraph([]);
          setIsError({label: '', message: ''})
          if(idDashboard.toLowerCase() !== 'overview'){
            currentDashboard.forEach(graphItem => {
              graphItem.payload['start_date'] = (type==='update')?getFilterValueList('startDate').format('YYYY-MM-DD'):dayjs().subtract(1, 'week').format('YYYY-MM-DD');
              graphItem.payload['end_date'] = (type==='update')?getFilterValueList('endDate').format('YYYY-MM-DD'):dayjs().format('YYYY-MM-DD');

              /*graphItem.payload['start_date'] = (type==='update')?getFilterValueList('startDate').format('YYYY-MM-DD HH:mm'):dayjs().subtract(1, 'week').format('YYYY-MM-DD HH:mm');
              graph Item.payload['end_date'] = (type==='update')?getFilterValueList('endDate').format('YYYY-MM-DD HH:mm'):dayjs().format('YYYY-MM-DD HH:mm');*/

              let filterToApply = (['social_pages', 'real_time'].includes(idDashboard.toLowerCase()) && graphItem.filtersList)
                ?[...filtersList, ...localFiltersList.filter(el => graphItem.filtersList.includes(el.name))]
                :filtersList;

              filterToApply.forEach((selectedFilter) => {
                let newSelected = (type==='update')?getFilterValueList(selectedFilter.name) : (selectedFilter.type !== "multiselector")?[]:DefaultValues[selectedFilter.name.replace(idDashboard+'_', '')]
                if((!Array.isArray(newSelected) && selectedFilter.type !== "multiselector")) newSelected = [newSelected]

                let newValue = (newSelected)? newSelected.map(el => el.id) : []
                graphItem.payload[selectedFilter.name.replace(idDashboard+'_', '')] = (['real_time'].includes(idDashboard.toLowerCase())) ? newValue[0] : newValue
              });
            });
          }

          setCurrentDashboardGraph([...currentDashboard])       
        } 
        else setIsError({ label: 'data', message: `Data for one or more charts not available` });
    };  // Dependency array to ensure `callMutationData` is stable

    const fetchFilters = () => {
      if(idDashboard==='social_pages') return axios.post(process.env.REACT_APP_BACKEND_URL+ 'social_filters', FilterData[idDashboard] );
      return axios.post(process.env.REACT_APP_BACKEND_URL+'filters', FilterData[idDashboard] );
    };

    const getFilters = () => {
      return new Promise((resolve, reject) => {
        fetchFilters()
          .then((response) => {
            resolve({ data: response.data });
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    function callMutationEntityFilters() {      
      getFilters()
        .then((response: any) => {
          let filtersData: FilterContextEl[] = response.data.map(el => {return {...el, id:idDashboard+'_'+el.id, name:idDashboard+'_'+el.name}});

          if(idDashboard.toLowerCase() === 'social_pages'){
            let globalFilters = [filtersData.filter(el => el.name.includes('page_name'))[0]];
            let localFiltersData = filtersData.filter(el => !el.name.includes('page_name')).map(el => { return{...el, type:"selector", selectedValue:el.optionList[0]}});
            let newList = [...globalFilters, ...localFiltersData];
            let newFlagList = [...Array.from({ length: globalFilters.length }, () => true), ...Array.from({ length: localFiltersData.length }, () => false)]

            setLocalFiltersList(localFiltersData)
            setFiltersList(globalFilters);
            addFilters(newList, newFlagList);
          }

          else setFiltersList(addFilters(filtersData, Array.from({ length: filtersData.length }, () => true)));
        })
        .catch((error) => {
          //setIsError({ label: 'Options', message: `Options for ${nameDashboard} dashboard not available` });
        });
    }

    const handlerReset = () => {
      setFilterValueList('startDate', dayjs().subtract(1, 'week'), dayjs().subtract(1, 'week'))
      setFilterValueList('endDate', dayjs(), dayjs())

      filtersList.forEach((selectedFilter) => {
        setFilterValueList(selectedFilter.id, [], null)
      });

      localFiltersList.forEach((selectedFilter) => {
        setFilterValueList(selectedFilter.id, [], null)
      });

      callMutationData('reset')
    };

    const handlerUpdate = () => {
      callMutationData();
    };

    React.useEffect(() => {
      if((filtersList || ['overview'].includes(idDashboard.toLowerCase())) && currentDashboardGraph.length === 0)
        callMutationData();
    }, [filtersList]);

    React.useEffect(() => {
      if(['social_pages'].includes(idDashboard.toLowerCase()) ) {
        let proxyFilters = FilterData[idDashboard]["columns"].filter(el => el !== 'metric_name').map((el) => {return {"name":"","id":"","impacted":[],"filtered": null,"optionList":[],"type":"multiselector","isAllSelected": false}})
        setFiltersList(proxyFilters)

        proxyFilters = FilterData[idDashboard]["localFilters"].map((el) => {return {"name":el,"id":"","impacted":[],"filtered": null,"optionList":[],"type":(el.includes('page_name'))?"multiselector":"selector","isAllSelected": false}})
        setLocalFiltersList(proxyFilters.filter(el => !el.name.includes('page_name')))
      }
      else if(idDashboard.toLowerCase() === 'real_time'){
            let newList = [...onairLocalFilter];
            let newFlagList = [...[false]]
            
            setLocalFiltersList(onairLocalFilter)
            setFiltersList([]);
            addFilters(newList, newFlagList)
          }
      else if(!['settings','help','overview'].includes(idDashboard.toLowerCase())) {
        let proxyFilters = FilterData[idDashboard]["columns"].map((el) => {return {"name":"","id":"","impacted":[],"filtered": null,"optionList":[],"type":"multiselector","isAllSelected": false}})
        setFiltersList(proxyFilters)
      }

      if(!['real_time','settings','help'].includes(idDashboard.toLowerCase()))callMutationEntityFilters();
    }, []);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            {!['settings','help','overview'].includes(idDashboard.toLowerCase()) && (filtersList.length > 0 || localFiltersList.length > 0 || ['uae'].includes(idDashboard.toLowerCase())) && 
              <Grid container spacing={{ xs: 2, md: 3 }} sx={{justifyContent:'space-between'}}>    
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <ThemeProvider theme={darkTheme}>

                    {!['real_time'].includes(idDashboard.toLowerCase()) &&
                      <Grid key={'start-date'} item container xs={12} md={6} lg={4} xl={12/(filtersList.length+3)} direction='row' sx={{ justifyContent: "center", alignItems: "center" }}>
                        <DateTimePicker
                          label="Data di Inizio"
                          value={getFilterValueList('startDate')}
                          onChange={(newValue) => {
                            setFilterValueList('startDate', newValue, newValue)
                          }}                        
                          sx={filter_style}
                          className="custom-filter"
                          maxDate={getFilterValueList('endDate')}
                          format="DD/MM/YYYY HH:mm"  // Formato per mostrare anche l'ora
                          ampm={false}
                          minutesStep={1}timeSteps={{ minutes: 1 }}
                          slots={{
                            actionBar: () => null,       // Rimuove la barra dei pulsanti (OK e Cancel)
                          }}
                        />
                      </Grid>
                    }

                    {!['real_time'].includes(idDashboard.toLowerCase()) &&
                      <Grid key={'end-date'} item container xs={12} md={6} lg={4} xl={12/(filtersList.length+3)} direction='row' sx={{ justifyContent: "center", alignItems: "center" }}>
                        <DateTimePicker
                          label="Data di Fine"
                          value={getFilterValueList('endDate')}
                          onChange={(newValue) => {
                            setFilterValueList('endDate', newValue, newValue);
                          }}
                          sx={filter_style}
                          className="custom-filter"
                          minDate={getFilterValueList('startDate')}
                          maxDate={dayjs()}
                          format="DD/MM/YYYY HH:mm"  // Formato per mostrare anche l'ora
                          ampm={false}
                          minutesStep={1}timeSteps={{ minutes: 1 }}
                          slots={{
                            actionBar: () => null,       // Rimuove la barra dei pulsanti (OK e Cancel)
                          }}
                        />
                      </Grid> 
                    }
                    
                    {filtersList.length > 0 && filtersList.map((filter, index) => (
                      <Grid key={'filter-'+index} item xs={12} md={6} lg={4} xl={12/(filtersList.length+3)} display="flex" justifyContent="center" alignItems="center">
                        <AutocompleteFilter key={'filter-AutocompleteFilter-'+idDashboard+'-'+index} 
                          showSelected={idDashboard !== 'contenuti_on_demand'}
                          filter_style={filter_style}
                          allSelected={isAllSelected(filter.name)}
                          value={getFilterValueList(filter.name)}
                          options={getFilteredOptionList(filter, (filter.filtered)? getFilterValueList(filter.filtered[0]) || []:[])}
                          caption={FilterData[idDashboard].columns_alias[index]}
                          name={filter.name}
                          setFilterValueList={setFilterValueList} />                        
                      </Grid>))
                    }

                    {['real_time'].includes(idDashboard.toLowerCase()) && localFiltersList.map((filter, index) => (
                      <Grid key={'filter-'+index} item xs={12} md={6} lg={4} xl={12/(localFiltersList.length+3)} display="flex" justifyContent="flex-start" alignItems="center">
                        <AutocompleteFilter key={'filter-AutocompleteFilter-local-'+index}
                          showSelected={true}
                          filter_style={filter_style}
                          allSelected={false}
                          value={getFilterValueList(filter.name)}
                          options={getFilteredOptionList(filter, (filter.filtered) ? getFilterValueList(filter.filtered[0]) || [] : [])}
                          caption={'Intervallo temporale'}
                          name={filter}
                          className="custom-filter-local"
                          multiple={false}
                          setFilterValueList={setFilterValueList} />
                      </Grid>

                      ))
                    }

                    <Grid key={'button-filter-grid'} item container xs={12} spacing={'24px'} xl={12/(filtersList.length+3)} direction='row' sx={{ justifyContent: "space-between", alignItems: "center" }}>
                      <Grid key={'button-filter1'} item xs={6}>
                          <button className={"button-query"} onClick={handlerReset}>Ripristina</button>
                      </Grid>

                      <Grid key={'button-filter2'} item xs={6}>
                          <button className={"button-query"} onClick={handlerUpdate}>Aggiorna</button>           
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                </LocalizationProvider>
              </Grid>
            }

            <Dashboard currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isLargeScreen={isLargeScreen} filtersList={localFiltersList.reduce((acc, el) => { acc[el.name] = el; return acc; }, {})} />
        </Grid>
    );
};

export default DashboardContainer;