import React from "react";
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from "./hooks/useAuth.ts";

interface UnprotectedRouteProps {
  element: React.FunctionComponentElement<any>;
}

const UnprotectedRoute: React.FC<UnprotectedRouteProps> = ({element}) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  return (!isLoggedIn? (
      element
    ) : (
      <Navigate to="/dashboards" replace state={{ from: location }} />
    )
  )
};

export default UnprotectedRoute;