import React from "react";
import { Grid, MenuItem, Select, Tab, Tabs } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import '../../style/graph.css';

export default function Footer ({entityList, value, handleChange, a11yProps, isLargeScreen}) {

    const scrollToTop = (e) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Animazione smooth
        });
    };

    return (
         <footer className="footer-container">
             <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid container item xs={4} xl={2.5} justifyContent='flex-start' alignItems='center'><img src={`${process.env.PUBLIC_URL}/images/kebula-inline-white-full-r.svg`} alt="logo" className="footer-image" /></Grid>
                
                <Grid container item xs={4} xl={8.5} justifyContent='center' alignItems='center'>
                {isLargeScreen ? (
                    // Mostra menu a tendina quando lo schermo è piccolo
                    <Select
                        value={value}
                        onChange={(event) => handleChange(event, event.target.value)}
                        className='select'
                        MenuProps={{
                            PaperProps: {
                            sx: {
                                color: 'white', // Colore del testo del menu
                            },
                            },
                        }}
                    >
                        {entityList && entityList.map((tabElement, index) => (
                            <MenuItem key={'MenuItem' + index} value={index}>
                                {tabElement.label}
                            </MenuItem>
                        ))}
                    </Select>
                    ) : (
                    <Tabs value={value} variant="scrollable" onChange={handleChange} aria-label="basic tabs example"
                        TabIndicatorProps={{
                        style: {
                            overflowY: 'auto',
                            backgroundColor: 'red', // Colore della barra indicatrice
                        },
                        }}
                        >
                        {entityList && entityList.map((tabElement, index) => {
                            return <Tab key={'Tab'+index}
                                label={tabElement['label']} {...a11yProps({index})} 
                                sx={{ textTransform: 'none', fontFamily: 'Custom_font',
                                color: 'white', // Colore del testo delle schede
                                '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                                }}
                                className='tab-title'/>
                        }
                        )}
                    </Tabs>
                    )}
                </Grid>

                <Grid container item xs={4} xl={1} justifyContent='end' alignItems='center' >
                    <ArrowUpwardIcon className="back-to-top-button" onClick={scrollToTop}/>
                </Grid>
            </Grid>
          </footer>
    )
}   