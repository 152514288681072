import React from 'react';
import ReactECharts from 'echarts-for-react';
import { formatDataLabel, formatNumber, formatNumLabel, getColorBasedOnIndex } from '../../utils/graphUtils.tsx';

const MixedChartComponent = ({ data, color = 'red', type = 'bar' }) => {

    const maxY1Value = (data.y1value) ? Math.max(...data.y1value) : 0;
    data.xvalue = data.xvalue.map(el => formatDataLabel(el))

    const option = {
        tooltip: {
            textStyle: {
                fontFamily: 'Custom_font',
                align: 'left'
            },
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: false,
                    color: '#ffffff'
                }
            },
            axisLabel: {
                fontFamily: 'Custom_font',
                formatter: formatDataLabel,
                color: '#fff',
            },
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: false },
                restore: { show: true },
                saveAsImage: { show: false }
            }
        },
        legend: {
            data: data && data.ylabel.map(el => el.charAt(0).toUpperCase() + el.slice(1).replaceAll('_', ' ')) ,
            textStyle: {
                fontFamily: 'Custom_font',
                color: '#fff',
            },
            itemStyle: {
                color: (color === 'red') ? '#B30000' : '#0096E6'
            }
        },
        grid: {
            top: '20%',
            bottom: '5%',
            left: (data.y1value.length)?'1%':'5%',
            right: '2%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: formatDataLabel(data.xvalue) || [],
                axisPointer: {
                    type: 'shadow'
                },
                axisLabel: {
                    fontFamily: 'Custom_font',
                    formatter: formatDataLabel,
                    color: '#fff',
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: data.ylabel.length > 0 && data.ylabel[0].charAt(0).toUpperCase() + data.ylabel[0].slice(1).replaceAll('_', ' '),
                min: 0,
                max: maxY1Value,
                interval: maxY1Value / 5,
                axisLabel: {
                    fontFamily: 'Custom_font',
                    formatter: formatNumLabel,
                    color: '#fff'
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                axisTick: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                splitLine: {
                    show: false
                }
            },
            {
                type: 'value',
                name: data.ylabel.length === 2 && data.ylabel[1].charAt(0).toUpperCase() + data.ylabel[1].slice(1).replaceAll('_', ' '),
                min: 0,
                max: Math.max(...data.y2value),
                interval: Math.max(...data.y2value) / 5,
                axisLabel: {
                    formatter: formatNumLabel,
                    color: '#fff'
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                axisTick: {
                    lineStyle: {
                        color: '#fff'
                    }
                },
                splitLine: {
                    show: false
                }
            }
        ],
        series: [
            {
                name: data.ylabel.length > 0 && data.ylabel[0].charAt(0).toUpperCase() + data.ylabel[0].slice(1).replaceAll('_', ' '),
                type: type,
                yAxisIndex: 0,
                tooltip: {
                    textStyle: {
                        fontFamily: 'Custom_font',
                        align: 'left'
                    },
                    valueFormatter: function (value) {
                        return formatNumber(value);
                    }
                },
                lineStyle: {
                    color: (color === 'red') ? '#cd6f70' : '#94cce2', // Cambia il colore della linea
                    width: 2
                },
                itemStyle: {
                    color: (params) => getColorBasedOnIndex(params.value, maxY1Value, color),
                    borderWidth: 2
                },
                symbolSize: 10,
                data: data.y1value || []
            },
            {
                name: data.ylabel.length === 2 && data.ylabel[1].charAt(0).toUpperCase() + data.ylabel[1].slice(1).replaceAll('_', ' '),
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    textStyle: {
                        fontFamily: 'Custom_font',
                        align: 'left'
                    },
                    valueFormatter: function (value) {
                        return formatNumber(value);
                    }
                },
                lineStyle: {
                    color: (color === 'red') ? '#B30000' : '#17becf', // Cambia il colore della linea
                    width: 2
                },
                itemStyle: {
                    color: (color === 'red') ? '#B30000' : '#0096E6', // Colore dei punti
                    borderColor: '#fff', // Colore del bordo dei punti
                    borderWidth: 2
                },
                symbolSize: 10,
                data: data.y2value || []
            }
        ]
    };

    return <ReactECharts option={option} notMerge={true} style={{ height: '400px', width: '100%' }} />;
};

export default MixedChartComponent;