import React, { useState } from 'react';
import { Tabs, Box, Tab } from '@mui/material';
import { SettingsUser } from '../elements/SettingsUser.tsx';
import Codifica from '../elements/Codifica.tsx';
import MainCard from "../elements/MainCard.tsx";
import { JobManagementComponent } from '../elements/JobManagementComponent.tsx';

const Settings = () => {
    const tabs = ['Utenti', 'Dati di origine', 'Gestione Job']
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example"
                TabIndicatorProps={{
                style: { overflowY: 'hidden', backgroundColor: 'red' },
                }}
            >
                {tabs.map((label, index) => {
                    return <Tab key={'Tab_Setting_'+index} label={label} {...a11yProps(index)} 
                    sx={{ textTransform: 'none', fontFamily: 'Custom_font',
                    color: 'white', // Colore del testo delle schede
                    '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                    }}
                    className='tab-title'/>             
                })}
            </Tabs> 
            
            <TabPanel key={0} value={selectedTab} index={0} label={tabs[0]} {...a11yProps(0)} 
                sx={{ textTransform: 'none', fontFamily: 'Custom_font',
                    color: 'white', // Colore del testo delle schede
                    '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                }}
                className='tab-title'>
                <MainCard element={<SettingsUser />} title={'Gestione utenti'} />
            </TabPanel>
                       
            <TabPanel key={1} value={selectedTab} index={1} label={tabs[1]} {...a11yProps(1)} 
                sx={{ textTransform: 'none', fontFamily: 'Custom_font',
                    color: 'white', // Colore del testo delle schede
                    '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                }}
                className='tab-title'>
                <MainCard element={<Codifica />} title={'Correzione dei dati di origine'} />
            </TabPanel>

            <TabPanel key={2} value={selectedTab} index={2} label={tabs[2]} {...a11yProps(2)} 
                sx={{ textTransform: 'none', fontFamily: 'Custom_font',
                    color: 'white', // Colore del testo delle schede
                    '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                }}
                className='tab-title'>
                <MainCard element={<JobManagementComponent />} title={'Gestione Job'} />
            </TabPanel>
        </Box>
    );
};

// Componente per il contenuto di ciascuna tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Settings;
