import React from "react";
import { Card, CardContent, Grid, IconButton, Tooltip } from "@mui/material";
import '../../style/graph.css';
import AutocompleteFilter from "./AutocompleteFilter.tsx";
import { filter_style } from "../utils/filterUtils.tsx";
import { useFilter } from "../providers/FilterProvider.tsx";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TextFilterComponent from "./TextFilterComponent.tsx";

interface MainIconCardDef {
    element: any, title: string, tooltip: string, filtersList?: any[], aliasFilter?: string[], icon: any, filter?: boolean, filterValue?: string, handleFilterChange?: any
}

const MainIconCard: React.FC<MainIconCardDef> = ({ element, title, tooltip= '', filtersList = [], aliasFilter = [], icon, filter=false,  filterValue = '', handleFilterChange = () => {}}) => {
    const { getFilterValueList, setFilterValueList, getFilteredOptionList } = useFilter();

    return (
        <Card style={{ borderRadius: '15px' }}>
            <CardContent>
                <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", paddingBottom:'10px' }} >
                    {title !== '' && (
                        <Grid container direction="row" sx={{ justifyContent: "flex-start", alignItems: "center", paddingBottom:((title !== '')?'10px':'0px')}} >
                            {icon && (
                                <Grid className="graph-title" item>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {icon}
                                    </div>
                                </Grid>
                            )}

                            <Grid item className="graph-title">
                                <span>{title}</span>
                                {filter && <TextFilterComponent filterValue={filterValue} handleFilterChange={handleFilterChange} ></TextFilterComponent>}
                            </Grid>

                            {tooltip && (
                                <Tooltip title={<span style={{ fontSize: "1.2rem" }}>{tooltip}</span>} arrow>
                                    <IconButton sx={{color:'white'}}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {filtersList &&
                                <Grid item
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    rowGap={'20px'}
                                    sx={{paddingBlock:'8px'}}
                                    >
                                    {Object.entries(filtersList).map(([key, values], index) => 
                                        <Grid item xs={6} key={'grid-AutocompleteFilter-local-'+index}>
                                            <AutocompleteFilter key={'filter-AutocompleteFilter-local-'+index}
                                            showSelected={true}
                                            filter_style={filter_style}
                                            allSelected={false}
                                            value={getFilterValueList(values.name)}
                                            options={getFilteredOptionList(values, (values.filtered) ? getFilterValueList(values.filtered[0]) || [] : [])}
                                            caption={aliasFilter[index]}
                                            name={key}
                                            className="custom-filter-local"
                                            multiple={false}
                                            setFilterValueList={setFilterValueList} />
                                        </Grid>
                                    )}
                                </Grid>
                            }                        
                        </Grid>
                    )}
                    
                </Grid>
                {element}

            </CardContent>
        </Card>
    );
}

export default MainIconCard;