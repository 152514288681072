import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from "./hooks/useAuth.ts";

interface ProtectedRouteProps {
  element: React.FunctionComponentElement<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation(); // Use the useLocation hook to get the current location

  return (isLoggedIn ? (
      element       
    ) : (
          <Navigate to="/login" replace state={{ from: location }} />
    )
  )
};

export default ProtectedRoute;