import React from "react";
import { Grid, InputAdornment, TextField, ThemeProvider } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import ScoreCardComponent from "../elements/ScoreCardComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import PieChartComponent from "../graphs/PieChart/PieChartComponent.tsx";
import MixedChartComponent from "../graphs/MixedChart/MixedChartComponent.tsx";
import TableComponent from "../elements/TableComponent.tsx";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MainIconCard from "../elements/MainIconCard.tsx";
import { darkTheme } from "../utils/graphUtils.tsx";
import { fecthDataGraph } from "../utils/promiseUtils.tsx";
import useAuth from "../hooks/useAuth.ts";
import { TikTokIcon } from "../utils/tiktokIcon.tsx";
import SearchIcon from '@mui/icons-material/Search';
import { GraphData } from "../elements/DashboardElement.tsx";

export const Social = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen, filtersList }) => {
    const {getToken} = useAuth();
    const socialList = ['facebook','instagram'];    
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const [dataGraph5, setDataGraph5] = React.useState<any>(null)
    const [dataGraph6, setDataGraph6] = React.useState<any>(null)
    const [dataGraph7, setDataGraph7] = React.useState<any>(null)
    const [dataGraph8, setDataGraph8] = React.useState<any>(null)
    const [dataGraph9, setDataGraph9] = React.useState<any>(null)

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0,1,2,3,4,5,6,7,8], setDataGraph);
    }, [currentDashboardGraph]);

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 2: setDataGraph3(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 3: setDataGraph4(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 4: setDataGraph5(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 5: setDataGraph6(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 6: setDataGraph7(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 7: setDataGraph8(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 8: setDataGraph9(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    const handleReduce = (list) => {
        return list.reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
        }, {});
    } 

    return (
        <ThemeProvider theme={darkTheme}>
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                gap='20px'>

                <Grid item xs={12} >{(dataGraph1 && dataGraph1.data)
                    ?<MainCard element={<ScoreCardComponent data={dataGraph1.data} direction="horizontal"/>} title={dataGraph1.title} tooltip={currentDashboardGraph[0].tooltip}/>
                    :<SkeletonComponent height='150px'/>}
                </Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                        <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph2 && dataGraph2.data)?<MainCard element={<PieChartComponent data={dataGraph2.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph2.title} tooltip={currentDashboardGraph[1].tooltip}/> :<SkeletonComponent/>} </Grid>
                        <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph3 && dataGraph3.data)?<MainCard element={<PieChartComponent data={dataGraph3.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph3.title} tooltip={currentDashboardGraph[2].tooltip}/> :<SkeletonComponent/>} </Grid>
                        {/* <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph4 && dataGraph4.data)?<MainCard element={<PieChartComponent data={dataGraph4.data} sizeGraph={'small'} isLargeScreen={isLargeScreen}/>} title={dataGraph4.title} tooltip={currentDashboardGraph[3].tooltip}/> :<SkeletonComponent/>} </Grid> */}
                </Grid>

                <Grid item xs={12}><div className="overview-tab-section">{'Insights sulle pagine'}</div></Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                        {socialList.includes('facebook') && 
                            <Grid item container direction="column" gap={'20px'} xs={12} md={12} lg={(12/socialList.length)-0.05} sx={{ justifyContent: "center", alignItems: "stretch" }}>
                                <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph5 && dataGraph5.data)?<MainIconCard element={<MixedChartComponent data={dataGraph5.data} color={'red'} type="line" />} title={dataGraph5.title} tooltip={currentDashboardGraph[4].tooltip} filtersList={handleReduce(Object.entries(filtersList).filter(([key, value]) => key === 'social_pages_facebook_metric_name'))} aliasFilter={['Facebook']} icon={<FacebookIcon sx={{ fontSize: 'max(2vw, 20px)' }} />}/> : <SkeletonComponent height={'582px'}/>} </Grid>
                            </Grid>}
                        {socialList.includes('instagram') && 
                            <Grid item container direction="column" gap={'20px'} xs={12} md={12} lg={(12/socialList.length)-0.05} sx={{ justifyContent: "center", alignItems: "stretch" }}>
                                <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph6 && dataGraph6.data)?<MainIconCard element={<MixedChartComponent data={dataGraph6.data} color={'red'} type="line"/>} title={dataGraph6.title} tooltip={currentDashboardGraph[5].tooltip} filtersList={handleReduce(Object.entries(filtersList).filter(([key, value]) => key === 'social_pages_instagram_metric_name'))} aliasFilter={['Instagram']} icon={<InstagramIcon sx={{fontSize:'max(2vw, 20px)'}}/>}/> : <SkeletonComponent height={'582px'}/>} </Grid>
                            </Grid>}
                        {/* {socialList.includes('tiktok') &&
                            <Grid item container direction="column" gap={'20px'} xs={12} md={12} lg={(12/socialList.length)-0.05} sx={{ justifyContent: "center", alignItems: "stretch" }}>
                                <Grid item xs={12} md={12} lg={(12/socialList.length)-0.05}>{(dataGraph7 && dataGraph7.data)?<MainIconCard element={<MixedChartComponent data={dataGraph7.data} color={'red'} type="line"/>} title={dataGraph7.title} filtersList={handleReduce(Object.entries(filtersList).filter(([key, value]) => key === 'social_pages_tiktok_metric_name'))} aliasFilter={['TikTok']} icon={<TikTokIcon />}/> : <SkeletonComponent height={'564px'}/>} </Grid>
                            </Grid>} */}
                </Grid>
                
                <Grid item xs={12}><div className="overview-tab-section">{'Insights sui contenuti'}</div></Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={12}>{(dataGraph8)
                        ?<MainIconCard element={<TableComponent rows={dataGraph8.data} columns={
                            GraphData['social_pages'][7].columnTableList?.map(el => { return{field: el, headerName: el.replace(/_/g, ' ')}})
                        }/>} title={dataGraph8.title} tooltip={''} icon={<FacebookIcon sx={{ fontSize: 'max(2vw, 20px)' }} />} />
                        :<SkeletonComponent height={'800px'}/>}
                    </Grid>
                </Grid>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                    >
                    <Grid item xs={12}>{(dataGraph9)
                        ?<MainIconCard element={<TableComponent rows={dataGraph9.data} columns={
                            GraphData['social_pages'][8].columnTableList?.map(el => {return{field:el, headerName:el.replace(/_/g, ' ')}})
                        }/>} title={dataGraph9.title} tooltip={''} icon={<InstagramIcon sx={{ fontSize: 'max(2vw, 20px)' }} />} />
                        :<SkeletonComponent height={'800px'}/>}
                    </Grid>
                </Grid>

            </Grid>
        </ThemeProvider>
    )
}