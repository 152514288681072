import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import useAuth from "../hooks/useAuth.ts";
import '../../style/login.css';
import { Box, Grid } from '@mui/material';
import {useNavigate} from "react-router-dom";

type LoginForm = {
    email: string;
    password: string;
};

export default function Login() {
    const { login, getToken } = useAuth();
    const [ error, setError ] = React.useState<string | null>(null);
    const navigate = useNavigate(); // Initialize the navigate function from React Router

    const onSubmit = async (data) => {
        try {
            await login(data.email, data.password);
            navigate('/dashboards');
        } catch (err: any) {
            if(err.message === 'Popup closed')
                setError("Login fallito");
            else if(err.message === 'Errore di certificato')
                setError("Insights momentaneamente non disponibile"); 
        }
    };

    return (
        <Grid container direction="row" className='login-container'
                sx={{ justifyContent: "center", alignItems: "center"}}>
            <Grid item container direction="column" className='login-box'
                sx={{ justifyContent: "center", alignItems: "center", rowGap:'30px'}}>
                    
                <Grid item container direction="row" gap={'30px'}
                    sx={{ justifyContent: "center", alignItems: "center" }}
                >
                    <img src={`${process.env.PUBLIC_URL}/images/RTL_logo.svg`} alt="logo" className="dashboard-image" />
                    <span className="dashboard-text">Insight Dashboards</span>
                </Grid>

                <button className="button-query login-button"  type="submit" title="Accedi" onClick={onSubmit}>Accedi</button>

                {error && <span className="error-message" style={{fontSize: '20px'}}><span>{error}</span></span>}                
             
            </Grid>
        </Grid>
    );
}
