import React from "react";
import { Grid, IconButton, Menu, MenuItem, Select, Tab, Tabs } from "@mui/material";
import '../../style/graph.css';
import useAuth from "../hooks/useAuth.ts";
import MenuIcon from '@mui/icons-material/Menu';

export default function Header ({ entityList, value, handleChange, a11yProps, refs, isLargeScreen}) {
    const { user, logout } = useAuth();
    const regexString = /( |\.|@)/g  // This what i have figured but its not working :(
    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleLogout = async () => {
        // Implement your logout logic here
        logout();
    };

    const handleCapitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const handleMenuClick = (action) => {
        setAnchorEl(null);
        handleChange(null, action)
    };

    return (
        <Grid className='header'
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
        >
            <Grid item xs={1.75} className='rtl-logo-container' sx={{ alignItems:'left' }}>
                <img src={`${process.env.PUBLIC_URL}/images/RTL_logo.svg`} style={{ width: '80%', height: 'auto' }}/>
            </Grid>

            <Grid container item xs>
                <Grid
                    container item
                    direction="column"
                    justifyContent="space-between"
                    alignItems="stretch"
                >
                    <Grid container item className='header-container'>                
                        <Grid item xs={8} md={9} className='title-container' >
                            <span className='title'>{entityList && entityList.length > 0 && `${entityList[value]['title'].toUpperCase()}`}</span>
                        </Grid>
                        
                        <Grid item xs={4} md={3} className='logo-container' sx={{minHeight:'8vw'}} >
                            <img src={`${process.env.PUBLIC_URL}/images/kebula_logo_full_white_r.svg`} style={{ width: '45%', height: 'auto', paddingBlock:'5%' }}/>
                        </Grid>                            

                    </Grid>
                    
                    <Grid container item sx={{justifyContent: "space-between"}}>
                        <Grid container item xs={4} md={1.8} justifyContent='flex-start' alignItems='center' >
                            <span className='hello_tag'>{`Ciao, ${handleCapitalize(user?.name?.split(regexString)[0]) || ''}`}</span>
                        </Grid>

                        <Grid container item xs={6} md={9.4} justifyContent='flex-end' alignItems='flex-end'>
                            {(isLargeScreen) ? (
                                // Mostra menu a tendina quando lo schermo è piccolo
                                entityList && entityList.length > 0 && 
                                    <Select
                                        value={value}
                                        onChange={(event) => handleChange(event, event.target.value)}
                                        className='select'
                                        
                                        MenuProps={{
                                            PaperProps: {
                                            sx: {
                                                color: 'white', // Colore del testo del menu
                                                backgroundColor: 'var(--background-filter)',
                                            },
                                            },
                                        }}
                                    >
                                        {entityList && entityList.map((tabElement, index) => (
                                            <MenuItem key={'MenuItem' + index} value={index}>
                                                {tabElement.label}
                                            </MenuItem>
                                        ))}
                                    </Select>                            
                                ) : (
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        TabIndicatorProps={{ style: { overflowY: 'hidden', backgroundColor: 'red' } }}
                                        TabScrollButtonProps={{ sx: { color: 'white' } }}
                                    >
                                        {entityList.map((tabElement, index) => (
                                            <Tab
                                                key={'Tab' + index}
                                                ref={refs.current[index]}
                                                label={tabElement.label}
                                                {...a11yProps(index)}
                                                sx={{
                                                    textTransform: 'none',
                                                    fontFamily: 'Custom_font',
                                                    color: 'white', // Colore del testo delle schede
                                                    '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                                                    display: ['Impostazioni', 'Aiuto'].includes(tabElement.label) ? 'none' : 'flex', // Nascondi le schede non desiderate
                                                }}
                                                className="tab-title"
                                            />
                                        ))}
                                    </Tabs>

                                )
                            }
                            
                        </Grid>

                        <Grid container item xs={0.8} sx={{ justifyContent:'flex-end', alignItems:'center'}}>
                            {!isLargeScreen ?
                                <>
                                    <IconButton
                                        aria-label="menu"
                                        onClick={(event) => setAnchorEl(event.currentTarget)}
                                        sx={{ color: 'white' }}
                                    >
                                        <MenuIcon/>
                                    </IconButton>
                                    
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                        MenuListProps={{
                                            'aria-labelledby': 'menu-button',
                                        }}
                                        PaperProps={{
                                            sx: {
                                                color: 'white',
                                                backgroundColor: 'var(--background-filter)',
                                            },
                                        }}
                                    >
                                        {entityList.find(el => el.label === 'Impostazioni') && <MenuItem onClick={() => handleMenuClick(entityList.findIndex(el => el.label === 'Impostazioni'))}>Impostazioni</MenuItem>}
                                        {entityList.find(el => el.label === 'Aiuto') && <MenuItem onClick={() => handleMenuClick(entityList.findIndex(el => el.label === 'Aiuto'))}>Aiuto</MenuItem>}
                                        <MenuItem onClick={() => handleLogout()}>Esci</MenuItem>
                                    </Menu>
                                </>
                            : <span className="logout-button" onClick={handleLogout}>Esci</span>}
                        </Grid>
                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}