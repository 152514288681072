import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';

export default function DialogComponent({ open, handleClose, typeOperation }) {
  const [text, setText] = React.useState<string|null>(null);

  React.useEffect(() => {
    let newText = ''

    switch(typeOperation){
      case 'save': newText = 'Sicuro di volere salvare le correzioni?'; break;
      case 'reset': newText = 'Sicuro di volere ripristinare i dati al loro stato inziale?'; break;
      case 'refresh': newText = 'Sicuro di volere ricaricare i dati? Eventuali modifiche non salvate verranno perse'; break;
    } 
    setText(newText)
  }, [typeOperation])

  return (<>
      {text &&
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{minWidth: '360.700px'}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Attenzione"}</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description"> {text} </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => handleClose(false, typeOperation)} color="primary">
              No
            </Button>
            <Button onClick={() => handleClose(true, typeOperation)} color="primary" autoFocus>
              Sì
            </Button>
          </DialogActions>
        </Dialog>
      }
      </>
    );
  };