import React from "react";
import { Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph } from "../utils/promiseUtils.tsx";
import MixedLineBarChartComponent from "../graphs/MixedChart/MixedLineBarChartComponent.tsx";

const UAE = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen }) => {
  
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const {getToken} = useAuth();

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    React.useEffect(() => {
        let newCurrentDashboardGraph = currentDashboardGraph.map(el => {return(el.title!== 'SCORECARD')? {...el, payload:{'start_date':el.payload.start_date, 'end_date':el.payload.end_date, 'granularity':el.payload.granularity}}:el});      
        fecthDataGraph(newCurrentDashboardGraph, getToken, idDashboard, [0,1], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph1 && dataGraph1.data) 
                    ?<MainCard element={<MixedLineBarChartComponent data={dataGraph1.data.data} />} title={dataGraph1.title} tooltip={currentDashboardGraph[0].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph2 && dataGraph2.data)
                    ?<MainCard element={<MixedLineBarChartComponent data={dataGraph2.data.data}/>} title={dataGraph2.title} tooltip={currentDashboardGraph[1].tooltip}/>
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

        </Grid>        
    )
}

export default UAE;