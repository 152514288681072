import React from 'react';
import useAuth from '../components/hooks/useAuth.ts';
import { Navigate, useLocation } from 'react-router-dom';

const DefaultRoute: React.FC = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation(); // Use the useLocation hook to get the current location

  return isLoggedIn ? (
    <Navigate to="/dashboards" replace state={{ from: location }} />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default DefaultRoute;