import React from 'react';
import EChartsReact from "echarts-for-react";
import { formatDataLabel, formatGrouppedLegend, formatNumber, formatNumLabel, generateColorPalette } from '../../utils/graphUtils.tsx';
import '../../../style/graph.css'; // Importa il file CSS

const BarChartWithLabelsComponent = ({ data, color = 'red', showLabel= false }) => {
    const yAxisData = data['yAxis'];
    data.xAxis = data.xAxis.map(el => formatDataLabel(el))

    // Trova i valori minimi e massimi per creare un gradiente
    // Genera una scala di colori rossi
    const colors = generateColorPalette(data['yAxis'], color);

    const option = {
        tooltip: {
            textStyle: {
                fontFamily: 'Custom_font',
                align: 'left' // Allinea il testo del tooltip a sinistra
            },
            valueFormatter: formatNumber,
            trigger: 'item',
            axisPointer: {
                type: 'shadow',
                label: {
                    color: '#ffffff' // Colore del testo del tooltip
                }
            }
        },
        grid: {
            top: '3%',
            bottom: '1%',
            left: '0%',
            right: '0%',
            containLabel: true
        },
        xAxis: [
            {  
                type: 'category',
                data: data['xAxis'] || [],
                axisTick: {                    
                    alignWithLabel: true
                },
                axisLabel: {
                    fontFamily: 'Custom_font',
                    color: '#fff', // Colore delle etichette dell'asse Y
                    interval: 0, // Mostra tutte le etichette
                },
                nameLocation: 'end', // Posizione del nome dell'asse Y
                nameTextStyle: { 
                    fontSize: 18, // Dimensione del testo del nome dell'asse Y
                    color: '#fff' // Colore del nome dell'asse Y
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse y
                }
            }
        ],
        yAxis: [
            {  
                type: 'value',
                axisLabel: { 
                    formatter: formatNumLabel,
                    fontFamily: 'Custom_font',
                    alignWithLabel: true,
                    color: '#fff' // Colore delle etichette dell'asse X
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse x
                }
            }
        ],        
        series: [
            {
                name: 'Value',
                type: 'bar',
                barWidth: '60%',
                data: yAxisData || [],
                label: {
                    show: showLabel, // Mostra le etichette se showLabel è true
                    fontSize: 17, // Dimensione del testo del nome dell'asse Y
                    fontFamily: 'Custom_font',
                    color: '#fff',
                    textBorderColor: '#000', // Colore del bordo del testo (bianco)
                    textBorderWidth: 2, // Larghezza del bordo
                    offset: [0, -5]
                },
                itemStyle: {
                    fontFamily: 'Custom_font',
                    color: (params) => colors[params.dataIndex]
                }
            }
        ]
    };

    return (
        <div className="chart-container">
            <EChartsReact option={option} notMerge={true} className={'chart-wrapper'} style={{ height: `400px`, width: '100%' }} />
        </div>
    );
}

export default BarChartWithLabelsComponent;
