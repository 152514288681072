import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';

export default function DialogComponent({ open, handleClose }) {
  const [text, setText] = React.useState<string|null>('Preferisci applicare le codifiche immediatamente o al prossimo aggiornamento programmato?');

  return (<>
      {text &&
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{minWidth: '360.700px'}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Attenzione"}</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description"> {text} </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => handleClose(false, '')} color="primary">
              Annulla
            </Button>
            <Button onClick={() => handleClose(true, 'apply')} color="primary" autoFocus>
              Applica ora
            </Button>
            <Button onClick={() => handleClose(true, 'save')} color="primary" autoFocus>
              Dopo
            </Button>
          </DialogActions>
        </Dialog>
      }
      </>
    );
  };