import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const TextFilterComponent = ({ filterValue, handleFilterChange}) => {

    return (
        <div style={{ display: 'flex' }}>
            <TextField
                variant='outlined'
                placeholder='Filtra...'
                value={filterValue}
                onChange={(e) => handleFilterChange(e.target.value)}
                sx={{
                    width: '100%', // Si adatta al contenitore
                    '& .MuiOutlinedInput-root': {
                        height: '33px', // Imposta l'altezza massima
                        '& input': {
                            padding: '0', // Aggiusta il padding interno
                            color: 'white', // Colore del testo bianco
                        },
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" sx={{ padding: 0 }}>
                            <SearchIcon sx={{ color: 'white' }} />
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    )
}

export default TextFilterComponent;