import React, { useState } from 'react';
import { Tabs, Box, Tab } from '@mui/material';
import CodificaTable from './CodificaTable.tsx';
import DataQuality from './DataQuality.tsx';

const Codifica = () => {
    let entityList= [
        {label: 'Audience', id: 'audience'},
        {label: 'Live', title: 'contenuti live', id: 'contenuti_live'},
        {label: 'On Demand', id: 'contenuti_on_demand'},
        {label: 'Musica', id: 'music'},
        {label: 'Advertising', id: 'advertising'},
        // {label: 'Piattaforme', id: 'platforms'},
        {label: 'Dati TER', id:'dati_ter'},
        {label: 'UAE', id:'uae'},
        {label: 'Indice di qualità', id: 'data_quality'}
    ];
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example"
                TabIndicatorProps={{
                    style: { overflowY: 'hidden', backgroundColor: 'red' },
                }}
            >
                {entityList.map((el, index) => {
                    return <Tab key={'Tab_Codifica_'+index} label={el['label']} {...a11yProps({index})} 
                        sx={{ textTransform: 'none', fontFamily: 'Custom_font',
                        color: 'white', // Colore del testo delle schede
                        '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                        }}
                        className='tab-title'/>
                })}
            </Tabs> 

            {entityList.map((el, index) => {
                return <TabPanel key={'Tab_Content_'+index} value={selectedTab} index={index} label={el['label']} {...a11yProps({index})} 
                    sx={{ textTransform: 'none', fontFamily: 'Custom_font', paddingBottom:'0px',
                        color: 'white', // Colore del testo delle schede
                        '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                    }}
                    className='tab-title'>
                    {(el.id !== 'data_quality')
                        ? <CodificaTable dashboardName={el['id'].replace(' ','_').toLowerCase()}/>
                        : <DataQuality />
                    }   
                </TabPanel>
            })}
        </>
    );
};

// Componente per il contenuto di ciascuna tab
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Codifica;
