import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { PieChart, PieSeriesOption } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { formatGrouppedLegend, formatNumber, generateColorPalettePie } from "../../utils/graphUtils.tsx";
import { useMediaQuery, useTheme } from "@mui/material";

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
]);

type EChartsOption = echarts.ComposeOption<
  echarts.EChartsCoreOption & PieSeriesOption
>;

const PieChartComponent = ({ data, color = 'red', sizeGraph = 'large', type='absolut', isLargeScreen }) => {
  // Sort data descending
  const sortedData = (data && [...data.data || []].sort((a, b) => {
      if (a.name && a.name.toLowerCase() === 'others') return -1; // 'other' viene sempre dopo
      if (b.name && b.name.toLowerCase() === 'others') return 1; // 'other' viene sempre dopo
      return a.value - b.value; // Ordine crescente per gli altri elementi
    })
  ) || [];

  const totalValue = (sortedData)? sortedData.reduce((sum, item) => sum + item.value, 0) : 0;
  const formattedData =
    (type === 'perc' && sortedData)
    ?  sortedData.map((item) => ({
        ...item,
        value: ((item.value / totalValue) * 100).toFixed(2),
      }))
    : sortedData;

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // Usa il breakpoint per determinare la larghezza dello schermo

  const option: EChartsOption = {
    color: generateColorPalettePie(sortedData.map(el => el.name).reverse(), formattedData.map(el => el.value), color).reverse(),
    tooltip: {
      valueFormatter: (value) => (type === 'perc')?formatNumber(value)+' %':formatNumber(value),
      trigger: "item",
      textStyle: {
          fontFamily: 'Custom_font',
          align: 'left' // Allinea il testo del tooltip a sinistra
      },
    },
    grid: {
            top: '20%',
            bottom: '5%',
            left: '20%',
            right: '0%',
            containLabel: true
    },
    legend: {
      show: !isMediumScreen,      
      formatter: (value) => (isLargeScreen || sizeGraph === 'large')? value : formatGrouppedLegend(value, 30),
      type: 'scroll',
      orient: "vertical",
      top: "center",
      left: "left",
      textStyle: {        
        fontFamily: 'Custom_font',
        color: "#fff", // White text for legend
      },      
      pageIconColor: '#fff', // Colore delle frecce di scorrimento
      pageTextStyle: {
          fontFamily: 'Custom_font',
          color: '#fff' // Colore del numero di pagina
      },
      data: (formattedData || []).slice().reverse() // Inverti l'ordine dei dati della legenda
    },
    series: [
      {
        type: "pie",
        radius: (sizeGraph==='large' || !isMediumScreen)?["0%", "80%"]:["0%", "50%"], // Adjusted inner and outer radius to make the pie chart smaller
        center: (isMediumScreen)?["50%", "50%"]:["60%", "50%"], // Optional: keep the pie chart centered
        data: formattedData || [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          fontFamily: 'Custom_font',
          show: true,
          formatter: "{b}", // Display the label and percentage
          color: "#fff", // White text for pie chart labels
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: (sizeGraph !== 'adapt')?"400px":'400px', width: "100%" }} // Container size remains the same
      notMerge={true}
    />
  );
};

export default PieChartComponent;
