import React, { useState, useCallback, useMemo } from 'react';
import { TreeView, TreeItem } from '@mui/lab';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { ExpandMore, ChevronRight } from '@mui/icons-material';

interface TreeNode {
    column: string;
    id: string;
    value: string;
    enabled: boolean;
    children: TreeNode[];
}

interface InteractiveTreeViewProps {
    listOfSections: TreeNode[];
    setListOfSections: React.Dispatch<React.SetStateAction<TreeNode[]>>;
}

const InteractiveTreeView: React.FC<InteractiveTreeViewProps> = ({ listOfSections, setListOfSections }) => {
    const [expanded, setExpanded] = useState<string[]>(() => {
        const collectAllNodeIds = (nodes: TreeNode[]): string[] => {
            return nodes.reduce<string[]>((ids, node) => {
                ids.push(node.id);
                if (node.children) {
                    ids.push(...collectAllNodeIds(node.children));
                }
                return ids;
            }, []);
        };
        return collectAllNodeIds(listOfSections);
    });

    const uncheckLeaf = useCallback((nodes: TreeNode[], flag: boolean): TreeNode[] => {
        return nodes.map((node) => ({
            ...node,
            enabled: flag,
            children: node.children ? uncheckLeaf(node.children, flag) : [],
        }));
    }, []);

    const updateCheckState = useCallback((nodes: TreeNode[], nodeId: string, isChecked: boolean): TreeNode[] => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let newCheck = false;
        return nodes.map((node) => {
            newCheck = node.enabled
            if (nodeId.includes(node.id)) {
                if (!node.enabled && isChecked) newCheck = true;
            }

            if (node.id === nodeId) {
                return {
                    ...node,
                    enabled: isChecked,
                    children: node.children ? uncheckLeaf( node.children, isChecked): [],
                };
            }
            else if (node.children) {
                return {
                    ...node,
                    enabled: newCheck,
                    children: updateCheckState(node.children, nodeId, isChecked),
                };
            }

            return node
        });
    }, [uncheckLeaf]);

    const handleToggle = useCallback((nodeId: string, isChecked: boolean) => {
        setListOfSections((prevData) => updateCheckState(prevData, nodeId, isChecked));
    }, [updateCheckState, setListOfSections]);

    const renderTreeItems = useCallback((nodes: TreeNode[]): React.ReactNode => {
        return nodes.map((node) => (
            <TreeItem
                key={node.id}
                nodeId={node.id}
                label={
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={node.enabled}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    handleToggle(node.id, e.target.checked);
                                }}
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        label={node.value}
                        onClick={(e) => e.stopPropagation()} // Prevent label click from toggling
                        sx={{ display: 'flex', color:'var(--text-color)', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start' }}
                    />
                }
                sx={{
                    '& .MuiTreeItem-iconContainer': {
                        color: 'white', // Imposta il colore delle frecce a bianco
                    },
                }}
            >
                {node.children ? renderTreeItems(node.children) : null}
            </TreeItem>
        ));
    }, [handleToggle]);

    const handleNodeToggle = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    }, []);

    return (
        <Box sx={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
            <TreeView
                //expanded={expanded}
                onNodeToggle={handleNodeToggle}
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
            >
                {renderTreeItems(listOfSections)}
            </TreeView>
        </Box>
    );
};

export default InteractiveTreeView;
