// AddUserDialog.js
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox, Grid } from '@mui/material';

const AddUserDialog = ({ open, onClose, onSubmit }) => {
    const [tipologia, setTipologia] = useState('');
    const [dettaglio, setDettaglio] = useState('');
    const [canale, setCanale] = useState('');
    const [n_utenti, setN_utenti] = useState('');
    const [moltiplicatore, setMoltiplicatore] = useState('');

    React.useEffect(() => {
        setTipologia('')
        setDettaglio('')
        setCanale('')
        setN_utenti('')
        setMoltiplicatore('')
    }, [open])

    const handleSubmit = () => {
        onSubmit({ tipologia, dettaglio, canale, n_utenti, moltiplicatore });
        onClose();
    };

    const disabledField = () => {
        return tipologia !== '' && dettaglio !== '' && canale !== '' && n_utenti !== '' && moltiplicatore !== '' 
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Inserisci i tuoi dati</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="Tipologia"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={tipologia}
                            onChange={(e) => setTipologia(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="Dettaglio"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={dettaglio}
                            onChange={(e) => setDettaglio(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="Canale"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={canale}
                            onChange={(e) => setCanale(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="Num. utenti"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={n_utenti}
                            onChange={(e) => setN_utenti(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="Moltiplicatore"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={moltiplicatore}
                            onChange={(e) => setMoltiplicatore(e.target.value)}
                        />
                    </Grid>

                </Grid>                
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => disabledField() && handleSubmit()} color="primary" disabled={!disabledField()}>
                    OK
                </Button>
                <Button onClick={onClose} color="secondary">
                    Annulla
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserDialog;