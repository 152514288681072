// AddUserDialog.js
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox, Grid } from '@mui/material';

const AddUserDialog = ({ open, onClose, onSubmit, dashboard, settingList }) => {
    const [email, setEmail] = useState('');
    const [nome_cognome, setFullName] = useState('');
    const [checkboxes, setCheckboxes] = useState(Array(9).fill(false));

    React.useEffect(() => {
        setEmail('')
        setFullName('')
        setCheckboxes(Array(9).fill(false))
    }, [open])


    const handleCheckboxChange = (index) => {
        const newCheckboxes = [...checkboxes];
        newCheckboxes[index] = !newCheckboxes[index];
        setCheckboxes(newCheckboxes);
    };

    const handleSubmit = () => {
        onSubmit({ email, nome_cognome, checkboxes });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Inserisci i tuoi dati</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="email"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            margin="dense"
                            label="Nome e Cognome"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={nome_cognome}
                            onChange={(e) => setFullName(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <div>
                    <h3>Seleziona le dashboard a cui dare accesso:</h3>
                    <Grid container spacing={2}>
                        {checkboxes.map((checked, index) => (
                            <Grid item xs={6} sm={4} key={index}> {/* Adjust xs and sm for responsive design */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={() => handleCheckboxChange(index)}
                                        />
                                    }
                                    label={dashboard[index]}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => (email !== '' && nome_cognome !== '') && handleSubmit()} color="primary" disabled={email === '' && nome_cognome === ''}>
                    OK
                </Button>
                <Button onClick={onClose} color="secondary">
                    Annulla
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddUserDialog;