import React from 'react';
import '../../style/fullscreenSpinner.css';

const FullscreenSpinner = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="overlay">
      <div className="spinner" />
    </div>
  );
};

export default FullscreenSpinner;
