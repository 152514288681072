import React from "react";
import { Skeleton } from "@mui/material";
import '../../style/home.css';

const SkeletonComponent = ({height='500px'}) => {
    return (
        <Skeleton
            className="skeleton"
            variant="rectangular"
            width={'100%'}
            height={height}
        />
    )
}

export default SkeletonComponent;