import React from 'react';
import ReactECharts from 'echarts-for-react';
import { formatDataGVaux, formatNumber } from '../../utils/graphUtils.tsx';
import { BorderAll, BorderAllRounded } from '@mui/icons-material';

const MixedLineBarChartComponent = ({ data }) => {
    const option = {
        tooltip: {
            textStyle: {
                fontFamily: 'Custom_font',
                align: 'left'
            },
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {
                    show: false,
                    color: '#ffffff'
                }
            },
            axisLabel: {
                fontFamily: 'Custom_font',
                color: '#fff',
            },
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: false },
                restore: { show: true },
                saveAsImage: { show: false }
            }
        },
        grid: {
            top: '20%',
            bottom: '5%',
            left: (data.y1value.length)?'1%':'5%',
            right: '1%',
            containLabel: true
        },
        legend: {
            data: data.ylabel || [],
            textStyle: {
                color: '#fff' // White text for legend
            }
        },
        xAxis: [
            {
                type: 'category',
                data: data.xlabel || [],
                axisPointer: {
                    type: 'shadow'
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff' // White line for x-axis
                    }
                },
                axisLabel: {
                    color: '#fff' // White text for x-axis labels
                },
                axisTick: {
                    show: false
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Percentuali',
                axisLabel: {
                    formatter: (value) => (value*100)+'%',
                    color: '#fff' // White text for y-axis labels
                },
                nameTextStyle: {
                    color: '#fff' // White text for y-axis name
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff' // White line for y-axis
                    }
                },
                axisTick: {
                    show: false
                }
            },
            {
                type: 'value',
                name: 'Vaux',
                axisLabel: {
                    formatter: formatDataGVaux,
                    color: '#fff' // White text for secondary y-axis labels
                },
                nameTextStyle: {
                    color: '#fff' // White text for secondary y-axis name
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff' // White line for secondary y-axis
                    }
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                }
            },
        ],
        series: [
           {
                name: data.ylabel && data.ylabel[0],
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(value);
                    },
                    textStyle: {
                        color: '#a64d79' // Colore del testo nel tooltip per questa serie
                    },
                },
                lineStyle: {
                    width: 10, // Imposta lo spessore della linea
                    color: '#a64d79', // Colore della linea
                    type: 'solid' // Tipo di linea (può essere 'solid', 'dashed', ecc.)
                },
                symbol: 'circle', // Tipo di simbolo (cerchio)
                symbolSize: 10, // Aumenta la grandezza dei punti
                itemStyle: {
                    color: '#a64d79', // Imposta il colore di riempimento a trasparente
                    borderColor: '#fff', // Colore del bordo (bianco)
                    borderWidth: 3 // Spessore del bordo del punto
                },
                data: data.xvalue || []
            },
            {
                name: data.ylabel && data.ylabel[1],
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(Number((value * 100).toFixed(2)), 'en-US').replace('.',',') + ' %';
                    },
                    textStyle: {
                        color: 'red', // White text in tooltip for this series
                    },
                },
                itemStyle: {
                    opacity:0.7,
                    color: 'red', // Make the bars transparent
                    borderColor: 'red',
                    borderWidth: 2
                },
                data: data.y1value || []
            },
            {
                name: data.ylabel && data.ylabel[2],
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(Number((value * 100).toFixed(2)), 'en-US').replace('.',',') + ' %';
                    },
                    textStyle: {
                        color: '#fff', // White text in tooltip for this series
                    }
                },
                itemStyle: {
                    opacity:0.7,
                    color: '#ffbf00', // Make the bars transparent
                    borderColor: '#ffbf00', // Set white border for bars
                    borderWidth: 2
                },
                data: data.y2value || []
            },
            {
                name: data.ylabel && data.ylabel[3],
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return formatNumber(Number((value * 100).toFixed(2)), 'en-US').replace('.',',') + ' %';
                    },
                    textStyle: {
                        color: '#fff', // White text in tooltip for this series
                    }
                },
                itemStyle: {
                    opacity:0.7,
                    color: '#45818e', // Make the bars transparent
                    borderColor: '#45818e', // Set white border for bars
                    borderWidth: 2
                },
                data: data.y3value || []
            }
        ]
    };

    return <ReactECharts option={option} notMerge={true} style={{ height: '400px', width: '100%' }} />;
};

export default MixedLineBarChartComponent;
