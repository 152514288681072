import React from 'react';
import ReactECharts from 'echarts-for-react'
import { formatNumber } from '../../utils/graphUtils.tsx';
import { format } from 'node:path/posix';

const HeatmapChartComponent = ({ data, isLargeScreen, color = 'red' }) => {

    const option = {
        tooltip: {
            position: 'top',
            valueFormatter: formatNumber,
            textStyle: {
                fontFamily: 'Custom_font',
                align: 'left' // Allinea il testo del tooltip a sinistra
            },
        },
        grid: {
            left:'0%',
            right:'0%',
            top: '10%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: data['xAxis'] || [],
            splitArea: {
                show: true
            },
            axisLabel: {
                fontFamily: 'Custom_font',
                color: '#fff' // Colore bianco per le label dell'asse X
            }
        },
        yAxis: {
            type: 'category',
            data: data['yAxis'] || [],
            splitArea: {
                show: true
            },
            axisLabel: {
                fontFamily: 'Custom_font',
                color: '#fff' // Colore bianco per le label dell'asse Y
            }
        },
        visualMap: {
            textStyle: {
                fontFamily: 'Custom_font',
                color: "white",
            },
            min: (data["data"])? Math.min(...data['data'].map(row => row[2])):0,
            max: (data["data"])? Math.max(...data['data'].map(row => row[2])):0,
            calculable: true,
            orient: 'horizontal',
            left: 'center',
            inRange: {
                color: ['#ffe6c8', '#f00'] // Grayscale from black to white
            }
        },
        series: [
            {
                type: 'heatmap',
                data: (data['data'])? data['data'].map((item) => [item[1], item[0], item[2] || '-']) : [],
                label: {
                    fontFamily: 'Custom_font',
                    show: true,
                    fontSize:(!isLargeScreen)?'18px':'14px',
                    color: '#000', // Colore bianco per le label della serie di dati
                    formatter: ({ value }) => formatNumber(value[2])    
                },
                emphasis: {
                    itemStyle: {
                        fontFamily: 'Custom_font',
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    return <ReactECharts option={option} notMerge={true} style={{ height: '400px', width: '100%' }} />;
};

export default HeatmapChartComponent;
