import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { createTheme, Grid, ThemeProvider } from '@mui/material';
import SkeletonComponent from '../utils/Skeleton.tsx';
import FullscreenSpinner from './FullscreenSpinner.tsx';
import CustomSnackbar from '../utils/SnackBarCustomComponent.tsx';
import DialogComponent from './DialogComponent.tsx';
import EditIcon from '@mui/icons-material/Edit';
import ApplyDialogComponent from './ApplyDialogComponent.tsx';
import InteractiveTreeView from './ViewTree.tsx';
import AddTERDialog from './AddTERDialog.tsx';
import { darkTheme } from '../utils/graphUtils.tsx';
import { Height } from '@mui/icons-material';

export default function CodificaTable({ dashboardName }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const [isError, setIsError] = React.useState({ label: '', message: '', correct: false });
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openApplyDialog, setOpenApplyDialog] = React.useState(false);
  const [typeOperation, setTypeOperation] = React.useState('');
  const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);
  const [listOfSections, setListOfSections] = React.useState<any>();
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  const columns: GridColDef<any>[] =
  (dashboardName === 'dati_ter')
    ?[
      {
        field: 'tipologia',
        headerName: 'Tipologia',
        editable: true,
        flex: 1,
        resizable: false,
      },
      {
        field: 'dettaglio',
        headerName: 'Dettaglio',
        editable: true,
        flex: 1,
        resizable: false,
      },
      {
        field: 'canale',
        headerName: 'Canale',
        editable: true,
        flex: 1,
        resizable: false,
      },
      {
        field: 'n_utenti',
        headerName: 'Num. utenti',
        editable: true,
        flex: 1,
        resizable: false,
      },
      {
        field: 'moltiplicatore',
        headerName: 'Moltiplicatore',
        editable: true,
        flex: 1,
        resizable: false,
      },
    ]
  :(dashboardName === 'music')
    ?[
      {
        field: 'old_label',
        headerName: 'Label originale',
        flex: 1,
        resizable: false,
      },
      {
        field: 'new_label',
        headerName: 'Label codificata',
        editable: true,
        flex: 1,
        resizable: false,
      },
      {
        field: 'old_artist',
        headerName: 'Label artista originale',
        flex: 1,
        resizable: false,
      },
      {
        field: 'new_artist',
        headerName: 'Label artista codificata',
        editable: true,
        flex: 1,
        resizable: false,
      },
      {
        field: 'modified',
        headerName: 'Status',
        editable: false,
        flex: 0.3,
        resizable: false,
        renderCell: (params) => (
          <div style={{ textAlign: "center" }}>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Modificato"
            />
          </div>
        ),
      },
    ]
  :(dashboardName === 'uae')
    ?[
      {
        field: 'label',
        headerName: 'Label originale',
        editable: false,
        flex: 1,
        resizable: false,
      },
      {
        field: 'value',
        headerName: 'Valore',
        editable: true,
        flex: 1,
        resizable: false,
      }      
    ]
  :[
    {
      field: 'old_label',
      headerName: 'Label originale',
      flex: 1,
      resizable: false,
    },
    {
      field: 'new_label',
      headerName: 'Label codificata',
      editable: true,
      flex: 1,
      resizable: false,
    },
    {
      field: 'modified',
      headerName: 'Status',
      editable: false,
      flex: 0.1,
      resizable: false,
      renderCell: (params) => (
        params.value === true ? (
          <div style={{ textAlign: "center" }}>
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Modificato"
            />
          </div>
        ) : null
      ),
    },
    ]
  ;

  const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
    setSelectedRows(newSelection);
  };

  // Use processRowUpdate to handle row updates
  const processRowUpdate = (newRow) => {
    let updatedRows:any= []
    if(dashboardName === 'music')
      updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...row, new_label: newRow.new_label, new_artist: newRow.new_artist, status:'updated'} : row // Update the corresponding row
      );
    else if(dashboardName === 'dati_ter') {
      updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...row, canale: newRow.canale, dettaglio: newRow.dettaglio, moltiplicatore: Number(newRow.moltiplicatore), n_utenti: Number(newRow.n_utenti), tipologia: newRow.tipologia, status:'updated'} : row // Update the corresponding row
      );}
    else if(dashboardName === 'uae') {
      updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...row, value: newRow.value } : row // Update the corresponding row
      );}
    else
      updatedRows = rows.map((row) =>
        row.id === newRow.id ? { ...row, new_label: newRow.new_label, status:'updated'} : row // Update the corresponding row
      );
    setRows(updatedRows); // Set the updated rows to state
    return newRow; // Return the updated row
  };

  const applyLabelData = () => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BACKEND_URL + 'apply_labels', {table_name: dashboardName})
        .then((response) => { })
        .catch((error) => { setOpen(true); setIsError({ label: 'error', message: `Errore durante l'applicazione della codifica sui dati`, correct: false }); });
    })
  };

  const updateLabelData = (updatedLabelData, type) => {
    return new Promise((resolve, reject) => {
      if(dashboardName === 'contenuti_on_demand')
        axios.post(process.env.REACT_APP_BACKEND_URL + 'set_od_labels', {data: updatedLabelData})
        .then((response) => { setOpen(true); setIsError({ label: 'correct', message: `Aggiornamento dei dati eseguito con successo`, correct: true });  })
        .catch((error) => { setOpen(true); setIsError({ label: 'error', message: `Errore durante l'aggiornamento dei dati`, correct: false }); });
      
      else if(dashboardName === 'dati_ter')
        axios.post(process.env.REACT_APP_BACKEND_URL + 'save_ter_data', {data: rows})
        .then((response) => { setOpen(true); setIsError({ label: 'correct', message: `Aggiornamento dei dati eseguito con successo`, correct: true });  })
        .catch((error) => { setOpen(true); setIsError({ label: 'error', message: `Errore durante l'aggiornamento dei dati`, correct: false }); });
      
      else if(dashboardName === 'uae') {
        let newRows = rows.map(item => ({ [item.label]: Number(String(item.value).replace(" %", '')).toFixed(2) }));
        axios.post(process.env.REACT_APP_BACKEND_URL + 'uae/set_uae_settings', {data: newRows})
        .then((response) => { setOpen(true); setIsError({ label: 'correct', message: `Aggiornamento dei dati eseguito con successo`, correct: true });  })
        .catch((error) => { setOpen(true); setIsError({ label: 'error', message: `Errore durante l'aggiornamento dei dati`, correct: false }); });
      }

      else axios.post(process.env.REACT_APP_BACKEND_URL + 'save_labels', {table_name: dashboardName, labels: updatedLabelData})
        .then((response) => { if(type === 'apply') applyLabelData() })
        .catch((error) => { setOpen(true); setIsError({ label: 'error', message: `Errore durante l'aggiornamento dei dati`, correct: false }); });
      
    })
  };

  const resetLabelData = () => {
    let end_point: string;

    if (dashboardName === 'contenuti_on_demand') {
      end_point = 'reset_od_labels';
    } else if (dashboardName === 'dati_ter') {
      end_point = 'reset_ter_data';
    } else {
      end_point = 'reset_labels';
    }
    return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_BACKEND_URL + end_point , {table_name: dashboardName} )
      .then((response) => {
        handleRefresh();
        setOpen(true);
        setIsError({ label: 'correct', message: `Dati ripristinati`, correct: true });
      })
      .catch((error) => { setIsLoading(false); setOpen(true); setIsError({ label: 'error', message: `Errore durante il ripristino dei dati`, correct: false });});
    });
  };

  const getLabelData = () => {
    if (dashboardName === 'dati_ter') {
      return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'get_ter_data', {} )
        .then((response) => { resolve({ data: response.data.data }); })
        .catch((error) => { setIsLoading(false); setOpen(true); setIsError({ label: 'error', message: `Errore durante la richiesta dei dati`, correct: false }); });
      });
    } else if (dashboardName === 'uae') {
      return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'uae/get_uae_settings ', {} )
        .then((response) => { resolve({ data: response.data });  })
        .catch((error) => { setIsLoading(false); setOpen(true); setIsError({ label: 'error', message: `Errore durante la richiesta dei dati`, correct: false }); });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'get_labels', {table_name: dashboardName} )
        .then((response) => { resolve({ data: response.data }); })
        .catch((error) => { setIsLoading(false); setOpen(true); setIsError({ label: 'error', message: `Errore durante la richiesta dei dati`, correct: false }); });
      });
    }
  };

  const getOnDemandData = () => {
    return new Promise((resolve, reject) => {
      axios.post(process.env.REACT_APP_BACKEND_URL + 'get_od_labels', {} )
        .then((response) => {
          resolve({ data: response.data });
        })
        .catch((error) => { setIsLoading(false); setOpen(true); setIsError({ label: 'error', message: `Errore durante la richiesta dei dati`, correct: false }); });
    });
  };

  function callMutationUserData() {
    setIsLoading(true)
    getLabelData()
    .then((response: any) => {
      let labelData = response.data;
      if(dashboardName === 'uae') {
        labelData = labelData.data.map((el, index) => ({ ...el, id: el.content_id+'_'+index }));
        labelData = labelData.map(item => item.label === 'quota_di_mercato'? {...item, value: item.value + ' %'} : item)
        labelData = labelData.filter(el => el.label !== 'datetime')
      }
      else labelData = labelData.map((el, index) => ({ ...el, id: el.content_id+'_'+index }));
      setRows(labelData);
      setIsLoading(false)
    })
    .catch((error) => { setIsError({ label: 'error', message: `Errore durante la richiesta dei dati`, correct: false }); });
  }
   
  function callMutationOdData() {
    setIsLoading(true)
    getOnDemandData()
    .then((response: any) => {
      let newData = response.data;      
      setListOfSections(newData)
      setIsLoading(false)
    })
    .catch((error) => { setIsError({ label: 'error', message: `Errore durante la richiesta dei dati`, correct: false }); });
  }

  React.useEffect(() => {
    if(dashboardName !== 'contenuti_on_demand') callMutationUserData();
    else callMutationOdData()
  }, [dashboardName]);

  function handleSave(type) {
    let selectedData: any[] = rows.filter((row) => selectedRows.includes(row.id));
    if (selectedData.length === 0){selectedData = rows.filter(el => el.hasOwnProperty('status'))}

    if (dashboardName !== 'music') {selectedData = selectedData.map(el => {return {content_id:el.content_id, new_label: el.new_label }})}
    else {selectedData = selectedData.map(el => {return {content_id:el.content_id, new_label: el.new_label, new_artist: el.new_artist }})}

    updateLabelData(selectedData, type)
  }

  const handleRefresh = () => {
    if(dashboardName !== 'contenuti_on_demand') callMutationUserData();
    else callMutationOdData()
  };

  function handleReset() {
    setIsLoading(true)
    resetLabelData();
  }

  function handlerOpenDialog (type) {
    setOpenDialog(true)
    setTypeOperation(type)
  }

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  function handlerOpenApplyDialog () {
    setOpenApplyDialog(true)
  }

  function handleClose (confirmed, type) {
    setOpenDialog(false)
    if(confirmed){
      switch(type){
        case 'save': if(!['uae','dati_ter', 'contenuti_on_demand'].includes(dashboardName)) handlerOpenApplyDialog();
            else updateLabelData(listOfSections, type);
            break;
        case 'refresh': handleRefresh(); break;
        case 'reset': handleReset(); break;
      }
    }
  }

  function handleApplyClose (confirmed, type) {
    setOpenApplyDialog(false)
    if(confirmed){
      switch(type){
        case 'apply': handleSave('apply'); break;
        case 'save': handleSave('save'); break;
      }
    }
  }

  const handleAddTER = (data) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      axios.post(process.env.REACT_APP_BACKEND_URL + 'add_ter_data', data)
        .then((response) => {
          if(response.data && response.data.hasOwnProperty('message')){ setOpen(true); setIsError({label: 'correct', message: 'Riga creata con successo', correct: true})} 
          else { setOpen(true); setIsError({label: 'error', message: 'Errore durante l\'aggiunta dei dati', correct: false})}
        })
        .catch((error) => {setOpen(true); setIsError({label: 'error', message: 'Errore durante l\'aggiunta dei dati', correct: false}) });
        setIsLoading(false);
    })
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container
        direction="row"
        gap={2}
        sx={{
          justifyContent: "center",
          alignItems: "stretch",
        }}>

        <Grid item
          container
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "stretch",
            height: "stretch"
          }}
        >
          {(rows.length > 0 || dashboardName === 'contenuti_on_demand') ? (
            <Box sx={{ minHeight: ['contenuti_on_demand','uae'].includes(dashboardName)?'800px':'1400px', maxHeight: ['contenuti_on_demand','uae'].includes(dashboardName)?'800px':'2000px', width: '100%' }} >
              {(dashboardName === 'contenuti_on_demand') &&
                <div className="graph-title" style={{height:'50px'}}>
                  <span>FOLDERS</span>
                </div>
              }
              {(dashboardName !== 'contenuti_on_demand')
              ?<DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                processRowUpdate={processRowUpdate} // Correctly handle row updates
                onProcessRowUpdateError={(e) => console.log(e)} // Handle any processing errors
              />
              :
                listOfSections && <InteractiveTreeView listOfSections={listOfSections} setListOfSections={setListOfSections}/>
              }
            </Box>            
          ) : (
            <SkeletonComponent height={['contenuti_on_demand','uae'].includes(dashboardName)?'800px':'1400px'} />
          )}
          {!['uae'].includes(dashboardName) && selectedRows && selectedRows.length > 0 && <span style={{color:'white', width: '100%', textAlign:'left'}}>*Le azioni saranno applicate solo sulle righe selezionate</span>}
        </Grid>

        <Grid item container direction="row" sx={{justifyContent: "center", alignItems: "stretch", height: "stretch"}} gap={2} >
          <button className={"button-query"} onClick={ (e) => handlerOpenDialog('save') }>Salva le correzioni</button>
          <button className={"button-query"} onClick={ (e) => handlerOpenDialog('refresh') }>Ricarica i dati</button>
          <button className={"button-query"} onClick={ (e) => handlerOpenDialog('reset') }>Ripristina come da origine</button>
          {dashboardName === 'dati_ter' && <button className={"button-query"} onClick={handleOpenPopup}>Aggiungi Riga</button>}
        </Grid>

        <FullscreenSpinner isLoading={isLoading} />
        <CustomSnackbar open={open} setOpen={setOpen} message={isError.message} correct={isError.correct} />
        <DialogComponent open={openDialog} handleClose={handleClose} typeOperation={typeOperation}/>
        <ApplyDialogComponent open={openApplyDialog} handleClose={handleApplyClose}/>
        <AddTERDialog open={isPopupOpen} onClose={handleClosePopup} onSubmit={handleAddTER} />
      </Grid>
    </ThemeProvider>
  );
}