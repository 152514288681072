import React from "react";
import { Grid, ThemeProvider } from "@mui/material";
import StackedBarChartComponent from "../graphs/BarChart/StackedBarChartComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";
import MainIconCard from "../elements/MainIconCard.tsx";
import { darkTheme } from "../utils/graphUtils.tsx";
import MainCard from "../elements/MainCard.tsx";
import MixedChartComponent from "../graphs/MixedChart/MixedChartComponent.tsx";
import axios from "axios";

export const OnAir = ({ currentDashboardGraph, indexDashboard, idDashboard, isLargeScreen}) => {
    const { user, logout } = useAuth();
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [permits, setPermits] = React.useState<any>(null)

    const {getToken} = useAuth();

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
            case 1: setDataGraph2(JSON.parse(JSON.stringify({ title: currentDashboardGraph[index].title, data: responseData.data })));break;
        }
    }

    const getUserData = () => {
      return new Promise((resolve, reject) => {
        axios.post(process.env.REACT_APP_BACKEND_URL + 'users', {email:user?.email} )
          .then((response) => {
            resolve({ data: response.data });
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, [0,1], setDataGraph);

        getUserData()
        .then((response: any) => {
            try{
            let userData = response.data[0]?.session_with_token;        
            setPermits(userData)
            }catch(e) {setPermits(false)}
        })
        .catch((error) => { setPermits(false) });
    }, [currentDashboardGraph]);

    return (
        <ThemeProvider theme={darkTheme}>
            <Grid container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                gap='20px'>

                <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                >  
                    <Grid item xs={12}>{(dataGraph1 && dataGraph1.data)
                        ?<MainIconCard element={<StackedBarChartComponent data={dataGraph1.data} date={true} />} title={dataGraph1.title} tooltip={currentDashboardGraph[0].tooltip} icon={<div className="pulsing-dot"></div>} />
                        :<SkeletonComponent height={'514px'}/>}
                    </Grid>
                </Grid>

                {permits && <Grid item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    rowGap={'20px'}
                >  
                    <Grid item xs={12}>{(dataGraph2 && dataGraph2.data) 
                        ?<MainCard element={<MixedChartComponent data={dataGraph2.data} color={'red'} type="line" />} title={dataGraph2.title} tooltip={currentDashboardGraph[1].tooltip}/>
                        :<SkeletonComponent/>}
                    </Grid>
                </Grid>}    

            </Grid>
        </ThemeProvider>
    )
}